import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import api from '../../services/api';

import { useAuth } from '../../context/auth';
import { useOrder } from '../../context/order';
import { useCart } from '../../context/cart';
import { uuid } from 'uuidv4';

import { LoadingPage } from 'ui-kit-takeat';
import Menu from '../Menu';

const RedirectDelivery = props => {
  // const { restaurant_name, restaurant_code } = props.match.params;
  const [isLoading, setIsLoading] = useState(false);
  const [locationLimited, setlocationLimited] = useState('');
  const [modalAction, setModalAction] = useState([]);
  const history = useHistory();
  const { username } = useParams();
  const page = window.location.host;
  const {
    setRestaurant,
    setTable,
    setRestaurantAvatar,
    setRestaurantLocationLimited,
    setRestaurantIsOpen,
    setUserRecentlyProduct,
    setRestaurantAcceptsPix,
    setRestaurantAcceptsPaytime,
    setIsDeliveryByDistance,
    setRestaurantAddress,
    setRestaurantWithdrawalTime,
    setRestaurantDeliveryTime,
    setClientPaySms,
    setIsSmsServiceOptional,
    setRestaurantHasSmsService,
    setRestaurantAcceptsWithdrawal,
    setRestaurantAcceptsDelivery,
    setOrderSchedulingActive,
    setRestaurantDeliveryActive,
    setRestaurantWithdrawalActive,
    setRestaurantDeliveryPayments,
    setRestaurantAcceptsPaymentAtWithdrawal,
    setRestaurantRequiresUserCEP,
    setRestaurantOnlyQrcode,
    setRestaurantOnlyDelivery,
    setMinimumPrices,
    setMenuTranslationsEnabled,
    setSessionKey,
    setRedirectType,
    setRestaurantGreetinMessage
  } = useAuth();
  const { closeOrder } = useOrder();
  const { closeCart, closeCartPixel } = useCart();
  const [modalIsOpen, setIsOpen] = useState(false);
  Modal.setAppElement('#root');

  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '7px',
    },
  };

  function closeModal() {
    setIsOpen(false);
    modalAction[0]();
  }

  function openModal(phrase) {
    setIsOpen(true);
  }

  useEffect(() => {
    closeOrder();
    closeCartPixel();
    closeCart();
  }, [closeOrder, closeCart, closeCartPixel]);

  useEffect(() => {
    setUserRecentlyProduct('');
  });

  const fetchRestaurantData = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await api.get(`public/restaurant/${username}`);

      const {
        name,
        id,
        fantasy_name,
        has_service_tax,
        service_tax,
        is_location_limited,
        opened,
        instagram,
        has_pix,
        currency,
        adress,
        payment_methods,
        phone,
        greeting_message,
        has_credit_card,
        is_order_scheduling_active,
        only_qrcode,
        token_clube,
        has_clube,
        pixel_id,
        pixel_id2,
        is_active,
        meta_access_token,
        meta_access_token2,
        only_delivery,
        enable_translations,
      } = response.data;

      const {
        is_delivery_by_distance,
        delimit_by_area,
        time_to_delivery,
        time_to_withdrawal,
        has_sms_service,
        is_sms_service_optional,
        client_pay_sms,
        is_withdrawal_allowed,
        is_delivery_active,
        is_delivery_allowed,
        is_withdrawal_active,
        payment_at_withdrawal,
        cep_required,
        delivery_minimum_price,
        withdrawal_minimum_price,
      } = response.data.delivery_info;

      setMinimumPrices(delivery_minimum_price, withdrawal_minimum_price);

      if (response.data.avatar) {
        const { url } = response.data.avatar;
        setRestaurantAvatar(url);
      }

      if (has_pix) {
        setRestaurantAcceptsPix(true);
      } else {
        setRestaurantAcceptsPix(false);
      }

      if (has_credit_card) {
        setRestaurantAcceptsPaytime(true);
      } else {
        setRestaurantAcceptsPaytime(false);
      }

      const subscriptionIdFb = uuid();
      const subscriptionIdFb2 = uuid();

      setRestaurant(
        id,
        name,
        fantasy_name,
        has_service_tax,
        service_tax,
        instagram,
        currency,
        phone,
        token_clube,
        has_clube,
        pixel_id,
        pixel_id2,
        is_active,
        meta_access_token,
        meta_access_token2,
        subscriptionIdFb,
        subscriptionIdFb2
      );

      setRestaurantAddress(adress);
      setRestaurantOnlyQrcode(only_qrcode);
      setRestaurantOnlyDelivery(only_delivery);
      setRestaurantAcceptsWithdrawal(is_withdrawal_allowed);
      setRestaurantAcceptsPaymentAtWithdrawal(payment_at_withdrawal);
      setRestaurantRequiresUserCEP(cep_required);
      setRestaurantAcceptsDelivery(is_delivery_allowed);
      setOrderSchedulingActive(is_order_scheduling_active);
      setRestaurantWithdrawalActive(is_withdrawal_active);
      setRestaurantDeliveryActive(is_delivery_active);

      setRestaurantGreetinMessage(greeting_message)

      setRestaurantWithdrawalTime(time_to_withdrawal);
      setRestaurantDeliveryTime(time_to_delivery);
      setClientPaySms(client_pay_sms);
      setIsSmsServiceOptional(is_sms_service_optional);
      setRestaurantHasSmsService(has_sms_service);
      setRestaurantLocationLimited(is_location_limited);
      setlocationLimited(is_location_limited);
      setIsDeliveryByDistance(is_delivery_by_distance || delimit_by_area);
      setRestaurantIsOpen(opened);
      setMenuTranslationsEnabled(enable_translations);


      const paymentsFiltered = payment_methods
        .filter(
          method =>
            method.keyword !== 'online_ifood' && method.keyword !== 'prazo',
        )
        .sort((a, b) => {
          return a.id - b.id;
        });

      setRestaurantDeliveryPayments(paymentsFiltered);

      if (page === 'testgarcom.takeat.app') {
        try {
          const restaurantData = await api.get(
            `/public/delivery/qrcode/${username}`,
          );

          const { key } = restaurantData.data;

          const res = await api.get(`public/${username}/${key}`);

          const { table_number, table_type } = res.data.table;

          setRedirectType("delivery")

          setTable(table_number, username, table_type);
        } catch (error) {
          console.log(error);
        }
      } else if (page === 'cardapio.takeat.app') {
        setTable(-2, username, 'menu');
        setRedirectType("menu")
      }
      setIsLoading(false);
      // handleNavigate(id)
    } catch (error) {
      if (!error.response?.ok && error.response) {
        switch (error.response.data.errorType) {
          case 'service_unavailable':
            history.push(`/app/systemDeliveryError`);
            break;
          case 'wrong_session_key':
            localStorage.removeItem('@garcomdigital:sessionKey');
            setSessionKey('');
            break;
          default:
            history.push('/');
            // setIsLoading(false);
            // console.log(error.response.data.message);
            // openModal(
            //   `Houve algum problema de comunicação. Informe o seguinte erro ao restaurante: ${error.response.data.message}`,
            // );
            // setModalAction([
            //   () => {
            //     history.push('/');
            //   },
            // ]);
            break;
        }
      } else {
        console.log(error);
      }
    }
  }, [
    username,
    setRestaurant,
    setRestaurantAvatar,
    setRestaurantLocationLimited,
    setRestaurantIsOpen,
    setRestaurantAcceptsPix,
    setRestaurantAcceptsPaytime,
    setRestaurantAcceptsDelivery,
    setOrderSchedulingActive,
    setRestaurantAcceptsWithdrawal,
    setClientPaySms,
    setIsSmsServiceOptional,
    setIsDeliveryByDistance,
    setRestaurantAddress,
    setRestaurantDeliveryActive,
    setRestaurantHasSmsService,
    setRestaurantWithdrawalTime,
    setRestaurantDeliveryTime,
    setRestaurantDeliveryPayments,
    setRestaurantWithdrawalActive,
    setRestaurantAcceptsPaymentAtWithdrawal,
    setRestaurantRequiresUserCEP,
    history,
    setRestaurantOnlyQrcode,
    setRestaurantOnlyDelivery,
    setRestaurantGreetinMessage
  ]);

  useEffect(() => {
    fetchRestaurantData();
  }, [fetchRestaurantData, history, username]);



  // function handleNavigate(id) {
  //   if (locationLimited.toString() === 'true') {
  //     history.push('/app/needLocation');
  //   } else if (page === 'localhost:3000') {
  //     history.push(`/menu/${id}`);
  //   } else if (page === 'cardapio.takeat.app') {
  //     history.push(`/menu/${id}`);
  //   } else {
  //     history.push('/app/auto/');
  //   }
  // }

  return isLoading ? (
    <LoadingPage duration={3} text="Carregando o cardápio..." />
  ) : (
    <Menu />
  )

};

export default RedirectDelivery;
