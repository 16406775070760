import React, { useEffect } from 'react';
import { PriceContainer, PriceSpan } from './styles';
import { formatPrice } from '../../utils/formatPrice';

export const PriceTag = ({
  price,
  price_promotion,
  has_starting_price,
  minimum_complements,
}) => {
  return (
    <PriceContainer>
      {has_starting_price ? (
        <>
          <span>A partir de</span>
          <PriceSpan>
            {price_promotion
              ? formatPrice(parseFloat(price_promotion) + minimum_complements)
              : formatPrice(parseFloat(price) + minimum_complements)}
          </PriceSpan>
        </>
      ) : price > 0 ? (
        <>
          <PriceSpan isPromotion={!!price_promotion}>
            {formatPrice(price)}
          </PriceSpan>
          {price_promotion && (
            <span style={{ color: '#50a773' }}>
              {formatPrice(price_promotion)}
            </span>
          )}
        </>
      ) : (
        <PriceSpan>{formatPrice(minimum_complements)}</PriceSpan>
      )}
    </PriceContainer>
  );
};
