import React, { useEffect, useState } from 'react';
import {
  InputContainer,
  OtherReviews,
  ReviewForm,
  ReviewsPageContainer,
  SessionDetailDiv,
} from './styles';
import { Button } from 'ui-kit-takeat';
import { BackButton } from '../../components/BackButton';
import happyStar from '../../assets/images/happy_star.svg';
import thankRating from '../../assets/images/thank_rating.svg';
import ReactStars from 'react-rating-stars-component';
import { FaRegStar, FaStar } from 'react-icons/fa';
import { ReviewItem } from './ReviewItem';
import { AnimatePresence } from 'framer-motion';
import ReactInputMask from 'react-input-mask';
import api from '../../services/api';
import { useAuth } from '../../context/auth';
import { toast } from 'react-toastify';
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import { is } from 'date-fns/locale';

export const ReviewsPage = () => {
  const history = useHistory();
  const location = useLocation();
  const from = location.state?.from;
  const isClosingBill = from !== 'menu' && from !== 'welcome';
  const [formStep, setFormStep] = useState(isClosingBill ? 1 : 0);

  const [loading, setLoading] = useState(false);
  const [loadingSendReview, setLoadingSendReview] = useState(false);

  const [sessionDetails, setSessionDetails] = useState(null);
  const {
    userPhone,
    userName,
    userLogin,
    userToken,
    restaurantId,
    restaurantName,
    tableKey,
    formatValue,
    sessionKey,
  } = useAuth();
  const ratingOptions = [
    { type: 'food', text: 'Comida' },
    { type: 'service', text: 'Serviço' },
    { type: 'price', text: 'Preço' },
    { type: 'enviroment', text: 'Ambiente' },
  ];

  const defaultValues = {
    phone: userPhone,
    name: userName,
  };

  const [ratings, setRatings] = useState({
    experience: 0,
  });

  const [comment, setComment] = useState('');

  const getSessionDetails = async () => {
    try {
      const { data } = await api.get(`/client/sessions/details`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        params: {
          restaurant_name: restaurantName,
          table_code: tableKey,
        },
      });

      setSessionDetails(data);
    } catch (error) {
      console.log('Error getting session details', error);
    }
  };

  const sendReview = async () => {
    const data = {
      stars: ratings.experience,
      ratings: ratingOptions
        .filter(option => ratings[option.type] > 0)
        .map(option => {
          return {
            adjective: option.type,
            stars: ratings[option.type],
          };
        }),
      restaurant_id: restaurantId,
      comment: comment,
      table_code: tableKey,
      session_key: sessionKey,
    };

    setLoadingSendReview(true);

    try {
      await api.post('/client/ratings-2024', data);

      setFormStep(2);
    } catch (err) {
      console.log('Send review error: ', err);
      toast.error('Erro ao enviar avaliação. Tente novamente mais tarde.');
    }
    setLoadingSendReview(false);
  };

  useEffect(() => {
    if (formStep === 2) {
      setTimeout(() => {
        if (from === 'welcome') {
          history.push(`/auto/${restaurantName}/${tableKey}`);
        } else if (from === 'menu') {
          history.push(`/menu/${restaurantId}`);
        } else {
          history.push('/');
        }
      }, 2000);
    }
  }, [formStep]);

  useEffect(() => {
    if (userToken && !isClosingBill) {
      getSessionDetails();
    }
  }, [userToken]);

  useEffect(() => {
    const stars = document.querySelectorAll('[data-index]');
    console.log('Stars: ', stars);
  }, [formStep]);

  return (
    <ReviewsPageContainer>
      {formStep === 0 && (
        <>
          <BackButton>Voltar</BackButton>
          <ReviewForm
            defaultValue={defaultValues}
            id="testing"
            onSubmit={async data => {
              const values = {
                phone: data.target.phone.value,
                name: data.target.name.value,
              };

              data.preventDefault();
              if (
                !userToken ||
                values.phone !== defaultValues.phone ||
                values.name !== defaultValues.name
              ) {
                setLoading(true);
                await userLogin(
                  data.target.phone.value,
                  data.target.name.value,
                );
                setLoading(false);

                setFormStep(1);
              } else {
                setFormStep(1);
              }
            }}
          >
            <h1>Informe seus dados:</h1>
            <InputContainer>
              <label htmlFor="phone">Celular*</label>
              <ReactInputMask
                type="text"
                placeholder="(xx) xxxxx-xxxx"
                name="phone"
                id="phone"
                mask="(99) 99999-9999"
                maskPlaceholder={null}
                defaultValue={userPhone}
              />
            </InputContainer>

            <InputContainer>
              <label htmlFor="name">Nome</label>
              <input
                type="text"
                placeholder="Nome"
                name="name"
                defaultValue={userName}
              />
            </InputContainer>
          </ReviewForm>
          <Button
            form="testing"
            type="submit"
            title="Continuar"
            style={{ borderRadius: 40 }}
            disabled={loading}
            isLoading={loading}
            // onClick={() => setFormStep(1)}
          />
        </>
      )}
      {formStep === 1 && (
        <>
          <BackButton
            onClick={() => {
              setFormStep(0);
              setRatings({
                experience: 0,
                food: 0,
                service: 0,
                price: 0,
                enviroment: 0,
              });
            }}
          >
            Voltar
          </BackButton>
          <ReviewForm>
            {!isClosingBill && (
              <SessionDetailDiv margin={ratings.experience !== 0}>
                {sessionDetails?.session ? (
                  <h2>
                    Total da comanda:{' '}
                    {formatValue(sessionDetails.session.total_service_price)}
                  </h2>
                ) : (
                  <h2>Sem comanda aberta</h2>
                )}
              </SessionDetailDiv>
            )}
            <img src={happyStar} style={{ width: 120, margin: '0 auto' }} />
            <h1 style={{ textAlign: 'center' }}>
              Avalie a sua experiência com a gente!
            </h1>
            <ReactStars
              classNames={'stars'}
              count={5}
              value={ratings.experience}
              onChange={e => {
                setRatings(state => {
                  return { ...state, experience: e };
                });
              }}
              size={35}
              activeColor="#FFA814"
              color="#FFA814"
              emptyIcon={<FaRegStar />}
              filledIcon={<FaStar />}
            />
            <AnimatePresence>
              {ratings.experience !== 0 && (
                <OtherReviews
                  initial={{ height: 0 }}
                  animate={{ height: 'auto' }}
                  exit={{ height: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <div>
                    {ratingOptions.map((option, index) => (
                      <ReviewItem
                        key={index}
                        option={option}
                        value={ratings}
                        setValue={setRatings}
                      />
                    ))}
                  </div>
                  <span>Conte-nos o que podemos melhorar.</span>
                  <textarea
                    placeholder="Descreva aqui seu feedback"
                    value={comment}
                    onChange={e => setComment(e.target.value)}
                  />
                </OtherReviews>
              )}
            </AnimatePresence>
          </ReviewForm>
          <Button
            title="Avaliar"
            style={{ borderRadius: 40 }}
            disabled={ratings.experience === 0 || loadingSendReview}
            isLoading={loadingSendReview}
            onClick={() => sendReview()}
          />
        </>
      )}
      {formStep === 2 && (
        <ReviewForm>
          <img
            className="pulse-star"
            src={thankRating}
            style={{ width: 120, margin: '0 auto' }}
          />
          <h1 style={{ textAlign: 'center' }}>
            Obrigado por compartilhar sua opinião conosco!
          </h1>
        </ReviewForm>
      )}
    </ReviewsPageContainer>
  );
};
