/* eslint-disable */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as Yup from 'yup';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import formatTime from '../../utils/formatTime';
import Modal from 'react-modal';
import {
  Modal as ModalStrap,
  ModalBody as ModalBodyStrap,
  ModalHeader as ModalHeaderStrap,
  ModalFooter as ModalFooterStrap,
} from 'reactstrap';
import { uuid } from 'uuidv4';
import { IoIosArrowForward } from "react-icons/io";
import { FaArrowRight, FaChevronRight, FaCircle, FaClock, FaDollarSign, FaMapMarker, FaMapMarkerAlt, FaMarker, FaRegTimesCircle } from 'react-icons/fa';
import {
  FiChevronDown,
  FiCoffee,
  FiHelpCircle,
  FiCheckCircle,
  FiX,
} from 'react-icons/fi';
import { IoMdArrowBack } from 'react-icons/io';
import { TiTicket } from 'react-icons/ti';
import { TiClipboard } from 'react-icons/ti';
import { AiOutlineInstagram, AiOutlineLike } from 'react-icons/ai';
import Product from '../Product';
import MenuScrollNav from '../../components/MenuScrollNav';
import InputMask from 'react-input-mask';
import Input from '../../components/Input';

import { useCart } from '../../context/cart';
import { useAuth } from '../../context/auth';

import api from '../../services/api';

import fotoPadrao from '../../assets/images/fotoPadrao.png';
import logo_icon from '../../assets/images/iconTake.svg';
import Pix from '../../assets/images/logo_pix.png';
import Shape from '../../assets/images/shape.png';

import {
  Container,
  Content,
  MenuContent,
  MenuItemHeader,
  MenuItemBody,
  MenuItemDescription,
  MenuItemPrice,
  Badge,
  HeaderAction,
  AddToCart,
  Footer,
  ButtonGoToCart,
  ButtonGoToAccount,
  CountBadge,
  ButtonAction,
  SoldOff,
  ConfirmButton,
  ModalFooter,
  ModalTitle,
  RecentlyProduct,
  RecentlyProductHeader,
  ModalBody,
  RescueButton,
  RefuseButton,
  CancelButton,
  UserInfo,
  HasOrdersAdvice,
  CategoryTimes,
  ModalHelp,
  RestaurantLogo,
  RestaurantName,
  DeliveryHeader,
  DeliveryHeaderItemInfo,
  StatusRestaurantInfo,
  ModalRestaurantInfo
} from './styles';
import { useOrder } from '../../context/order';
import { Form } from '@unform/web';
import TextArea from '../../components/TextArea/Textarea';
import getValidationErrors from '../../utils/getValidationErrors';
import { useBaskets } from '../../context/BasketsContext';
import { Button, DefaultInput, LoadingPage } from 'ui-kit-takeat';
import { toast } from 'react-toastify';
import FlagsArea from '../../components/FlagsArea';
import { courtesyRestaurants } from '../../utils/constants';
import { PriceTag } from '../../components/PriceTag';
import { maskString, startingPrice } from '../../utils/functions';
import bannerCoins from '../../assets/images/bannerCoins.svg';
import bannerCoins2 from '../../assets/images/bannerCoins2.png';
import CashbackBanner from '../../components/ClubRegisterComponents/CashbackBanner';
import { ModalCashback } from '../../components/ModalCashback';
import {
  BirthdayInput,
  CashbackDisplay,
  ForgotPass,
  PhoneDisplay,
} from '../../components/ModalCashback/styles';
import { apiClub } from '../../services/apiClub';
import Color from 'color-thief-react';
import MenuScrollNavFixed from '../../components/MenuScrollNavFixed';

const Menu = props => {
  const { cart } = useCart();
  const { orders } = useOrder();
  const { hasOrder } = useBaskets();
  const {
    tableNumber,
    tableId,
    sessionKey,
    setSessionKey,
    restaurantFantasyName,
    setUserLocation,
    restaurantLocationLimited,
    restaurantIsOpen,
    restaurantDeliveryActive,
    restaurantWithdrawalActive,
    orderSchedulingActive,
    setUserRecentlyProduct,
    userRecentlyProduct,
    tableType,
    restaurantId,
    userPhone,
    billId,
    setRestaurantCouponCode,
    instagram,
    formatValue,
    restaurantName,
    tableKey,
    modalPix,
    setModalPix,
    cashbackAdvice,
    setCashbackAdvice,
    restaurantAcceptsPix,
    userName,
    restaurantPhone,
    restaurantUseCommand,
    hasOrderAdvice,
    sethasOrderAdvice,
    restaurantOnlyQrcode,
    restaurantPixelId,
    restaurantPixelId2,
    restaurantMetaToken,
    restaurantMetaToken2,
    hasFoodpark,
    foodparkKey,
    menuTranslationsEnabled,
    menuTranslationsLanguage,
    subscriptionIdPixel,
    subscriptionIdPixel2,
    setUser,
    userLogin,
    userToken,
    redirectType,
    restaurantAvatar,
    restaurantWithdrawallMinimumPrice,
    restaurantDeliveryMinimumPrice,
    restaurantAcceptsDelivery,
    restaurantAcceptsWithdrawal,
    restaurantGreetinMessage,
    restaurantAddress,
    setIsMenuVisible
  } = useAuth();

  const isCourtesy = courtesyRestaurants.includes(restaurantId);

  const [menu, setMenu] = useState([]);
  const formRef = useRef(null);
  const [modalPhrase, setModalPhrase] = useState('');
  const [modalAction, setModalAction] = useState([]);
  const [buttonCashbackAdvice, setButtonCashbackAdvice] = useState(true);
  // const { restaurant_id } = props.match.params;
  const history = useHistory();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalErrorOpen, setIsModalErrorOpen] = useState(false);
  const [modalProductIsOpen, setModalProductIsOpen] = useState(false);
  const [modalClubeIsOpen, setClubeIsOpen] = useState(false);
  const [itemProduct, setItemProduct] = useState();
  const [isModalPixOpened, setIsModalPixOpened] = useState(
    modalPix.toString() === 'true' &&
    orders.length > 0 &&
    restaurantAcceptsPix.toString() === 'true',
  );
  const [isModalWaiterHelpOpened, setIsModalWaiterHelpOpened] = useState(false);
  const [isModalHelperErrorOpened, setIsModalHelperErrorOpened] = useState(
    false,
  );
  const [showModal, setShowModal] = useState(null);
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
  const [showHasOrderAdvice, setShowHasOrderAdvice] = useState(
    hasOrderAdvice.toString() === 'true',
  );

  function toggleHasOrderAdvice() {
    sethasOrderAdvice(false);
    setShowHasOrderAdvice(false);
  }

  Modal.setAppElement('#root');

  function searchTableName(table) {
    if (table === 'balcony') {
      return 'Balcão';
    }

    if (table === 'delivery') {
      return 'Delivery';
    }

    return tableNumber;
  }

  function getActiveDays(days) {
    const activeDays = [];
    const weekDays = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
    for (let i = 0; i <= days.length; i++) {
      if (days[i] === 't') {
        activeDays.push({
          label: weekDays[i],
        });
      }
    }
    return (
      activeDays &&
      activeDays.map(day => <span key={day.label}>{day.label}</span>)
    );
  }

  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '7px',
      border: 'none',
      filter: 'drop-shadow(0 0 4px rgba(0, 0, 0, 0.2))',
    },
  };

  const cartAmount = cart.reduce((accum, curr) => accum + curr.amount, 0);

  async function handleOpenWhatspp() {
    if (restaurantPhone) {
      try {
        window.open(
          `https://api.whatsapp.com/send?phone=55${restaurantPhone.replace(
            /(-|\s|\(|\))/gi,
            '',
          )}&text=*Preciso%20de%20ajuda%20no%20sistema*`,
        );
      } catch (error) {
        alert('Erro ao solitar ajuda');
      }
    }
  }

  function closeModal() {
    setIsOpen(false);
  }

  function closeModalError() {
    setIsModalErrorOpen(false);

    modalAction[0]();
  }

  function openModal(phrase) {
    setIsOpen(true);
    setModalPhrase(phrase);
  }

  function openModalError(phrase) {
    setIsModalErrorOpen(true);
    setModalPhrase(phrase);
  }

  function toggleModalProduct() {
    setModalProductIsOpen(!modalProductIsOpen);
  }

  function openProductModal(item) {
    toggleModalProduct(!modalProductIsOpen);
    if (tableType === 'delivery') {
      setItemProduct({
        ...item,
        price:
          item.delivery_price_promotion ||
          item.delivery_price ||
          item.price_promotion ||
          item.price,
      });
    } else {
      setItemProduct({ ...item, price: item.price_promotion || item.price });
    }
  }

  function closeProductModal() {
    setModalProductIsOpen(false);
  }

  function toggleButtonCashbackAdvice() {
    setButtonCashbackAdvice(false);
    setCashbackAdvice(false);
  }

  function closeClubeModal() {
    setClubeIsOpen(false);
    setBirthday('');
    setButtonMessage('Resgatar Agora!');
  }

  function openClubeModal() {
    setClubeIsOpen(true);
  }

  function handleExpandCategory(e) {
    const element = document.getElementsByClassName(e);

    element[0].click();
  }

  function toggleModalPix() {
    setIsModalPixOpened(!isModalPixOpened);
    setModalPix(false);
  }

  function toggleModalWaiterHelp() {
    setIsModalWaiterHelpOpened(!isModalWaiterHelpOpened);
  }

  const [loadingMenu, setLoadingMenu] = useState(false);

  useEffect(() => {
    async function getMenu() {
      if (tableType.toString() === 'menu') {
        setLoadingMenu(true);

        const response = await api.get(
          `/public/restaurants/menu/${restaurantId}`,
          {
            params: {
              menuTable: 'true',
            },
          },
        );

        setMenu(response.data);
        setLoadingMenu(false);
      } else {
        setLoadingMenu(true);
        const response = await api.get(
          `/public/restaurants/menu/${restaurantId}`,
          {
            params: {
              gd: 'true',
            },
          },
        );
        setMenu(response.data);
        setLoadingMenu(false);
      }
    }
    if (!tableNumber) {
      history.push('/app/auto');
    } else {
      getMenu();
    }
  }, [restaurantId, history, tableNumber, tableType]);

  function handleGoToAccount() {
    if (tableType === 'prepaid') {
      history.push('/app/closeaccount');
    }
    if (!sessionKey) {
      openModal('Você ainda não fez pedido.');
    } else {
      history.push('/app/closeaccount');
    }
  }

  function getCategoryImage(cat) {
    const image = cat.products.filter(prd => prd.image !== null);

    if (cat.image) {
      return cat.image.url_thumb || cat.image.url;
    } else if (image) {
      return image[0]?.image.url_thumb || image[0]?.image.url;
    } else {
      return Pix;
    }
  }

  useEffect(() => {
    tableType === 'prepaid' && setSessionKey('');
  }, []);

  useEffect(() => {
    async function fetchSessionKey() {
      if (sessionKey && tableType !== 'delivery' && tableType !== 'prepaid') {
        try {
          await api.get(
            `/client/verify-session-key/${sessionKey}/restaurant/${restaurantName}/qrcode/${tableKey}`,
          );
        } catch (err) {
          if (!err.response.ok) {
            if (err.response.data.errorType === 'user_with_open_session') {
              const { redirect_url } = err.response.data.payload;

              openModalError(
                'Usuário já logado em outra mesa, estamos te redirecionando para sua mesa anterior',
              );
              setModalAction([
                () => {
                  window.location.href = redirect_url;
                },
              ]);
              setModalPix(true);
              setCashbackAdvice(true);
            }

            if (err.response.data.errorType === 'wrong_session_key') {
              localStorage.removeItem('@garcomdigital:sessionKey');
              setSessionKey('');

              setModalPix(true);
              setCashbackAdvice(true);
            }
          }
        }
      }
    }

    fetchSessionKey();
  }, [tableKey, restaurantName, sessionKey, setSessionKey, tableType]);

  function handleGoToCart() {
    if (cart.length < 1) {
      openModal('Seu carrinho está vazio.');
    } else {
      if (tableType === 'delivery') {
        history.push('/app/cart-delivery');
      } else {
        if (
          tableType === 'table' ||
          tableType === 'command' ||
          tableType === 'prepaid' ||
          tableType === 'lounge'
        ) {
          history.push('/app/cart');
        }
      }
    }
  }

  function handleGoToOrders() {
    history.push('/app/delivery-orders');
  }

  const getLocation = useCallback(() => {
    if ('geolocation' in navigator) {
      console.log('Geolocation Ok');
    } else {
      openModal(
        'Desculpe, mas seu navegador não aceita geolocalização, favor utilizar outro.',
      );
    }

    const geoError = () => {
      setUserLocation({});
    };

    const geoSuccess = position => {
      setUserLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    };

    navigator.geolocation.getCurrentPosition(geoSuccess, geoError);
  }, [setUserLocation]);

  useEffect(() => {
    if (restaurantLocationLimited.toString() === 'true') {
      getLocation();
    }
  }, [restaurantLocationLimited, getLocation]);

  const [totalTable, setTotalTable] = useState([]);
  useEffect(() => {
    async function fetchDatas() {
      const res = await api.get('client/table-bill', {
        params: { session_key: sessionKey },
      });

      const dataTable = res.data;

      setTotalTable(dataTable);
    }
    fetchDatas();
  }, [sessionKey]);

  //Clube
  const { setKeyClube, clube, setClube, restaurantOnlyDelivery } = useAuth();
  const [clubeClient, setClubeClient] = useState([]);
  const [rescues, setRescues] = useState([]);
  const [birthday, setBirthday] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [buttonMessage, setButtonMessage] = useState('Resgate Agora!');

  const splitEmail = clubeClient.clientEmail?.split('@');

  const clubEmail = splitEmail
    ? maskString(splitEmail[0]) + `@${splitEmail[1]}`
    : '';

  const handleRescue = useCallback(
    async ({ cashback }) => {
      try {
        setConfirmClubRescue({ state: 'disabled', message: 'Resgatando...' });
        setAllowRescue(false);
        let handlePhone = phone.replace(' ', '').replace('-', '');
        let data = {
          restaurantId,
          bill_id: billId,
          cashback,
          phone: handlePhone,
          session_key: sessionKey,
        };

        const response = await api.post(`/public/clube/rescues`, data);
        setCashbackRescued(cashback);
        setRescues(response.data.rescue);
        setShowModal(null);
        setConfirmClubRescue({ state: 'blocked', message: 'Resgatado!' });
        toast.success('Resgate efetuado com sucesso!');
      } catch (err) {
        console.log(err.errorBirthday);
        setButtonMessage('Resgatar Agora!');
        toast.error('Erro ao resgatar, tente novamente');
        setConfirmClubRescue({ state: 'enabled', message: 'Resgate agora!' });
      }
    },
    [restaurantId, billId],
  );

  function openClubeCadastro() {
    if (clube.key) {
      window.open(`https://app.takeat.club/${clube.key}`);
    }
  }

  function openInstagram() {
    if (instagram && instagram !== '' && instagram !== 'null') {
      window.open(`https://instagram.com/${instagram}`);
    }
  }

  function openFoodpark() {
    if (foodparkKey && foodparkKey !== '' && foodparkKey !== 'null') {
      window.location = `https://foodpark.takeat.app/${foodparkKey}`;
    }
  }

  const categoryRefs = useRef([]);
  const [categoryShowing, setCategoryShowing] = useState(0);

  const checkCategoryVisible = () => {
    categoryRefs.current.forEach((element, index) => {
      const rect = element.getBoundingClientRect();
      if (rect.top < 200 && rect.top > 110) {
        setCategoryShowing(index);
      }
    });
  };

  const [clubLogin, setClubLogin] = useState({
    phone: userPhone.replace(/(-|\s)/g, ''),
    password: '',
  });
  const [clubRelateLoading, setClubRelateLoading] = useState(false);
  const [confirmClubRescue, setConfirmClubRescue] = useState({
    state: 'disabled',
    message: 'Digite seu aniversário',
  });

  const confirmClubBirthday = async value => {
    setConfirmClubRescue({ state: 'disabled', message: 'Conferindo...' });
    const { data } = await api.post(`/public/clube/confirm-birthday`, {
      phone: userPhone.replace(/(-|\s)/g, ''),
      restaurantId,
      birthday: value,
    });

    if (data.success) {
      setConfirmClubRescue({ state: 'enabled', message: 'Resgate agora!' });
    }
    if (data.error) {
      setConfirmClubRescue({
        state: 'disabled',
        message: 'Data incorreta',
      });
    }
  };

  const loginAndRelate = async () => {
    setClubRelateLoading(true);
    let loginResponse;
    try {
      const { data } = await apiClub.post('public/sessions/client', clubLogin);

      loginResponse = data;
    } catch (err) {
      console.log('login club error: ', err);
      toast.error('Erro ao logar no clube, tente novamente');
    }
    try {
      await apiClub.post(
        '/client/create-relation',
        {
          key: clube.key,
        },
        {
          headers: {
            Authorization: `Bearer ${loginResponse.token}`,
          },
        },
      );

      getInfoCliente(userPhone);
      setShowModal(null);
    } catch (err) {
      console.log('relate club error: ', err);
      toast.error('Erro ao relacionar ao clube, tente novamente');
    }

    setClubRelateLoading(false);
  };

  const [loadingForgotPassword, setLoadingForgotPassword] = useState(false);
  const sendForgotPassword = async () => {
    setLoadingForgotPassword(true);
    try {
      const formattedPhone = userPhone.replace(/(\s|-)/g, '');
      await apiClub.post(`/public/request-change-password/${formattedPhone}`);

      toast.success('Email para redefinição de senha enviado com sucesso!');
      setForgotPasswordModal(false);
    } catch (err) {
      console.log('sendForgotPassword: ', err);
      toast.error(
        'Erro ao enviar email de redefinição de senha. Tente novamente mais tarde',
      );
    }
    setLoadingForgotPassword(false);
  };

  useEffect(() => {
    async function getClube() {
      const response = await api.get(`/public/clube/store/${restaurantId}`);
      if (!response.data.notFound) {
        setClube(response.data);
        setKeyClube(response.data.key);
        try {
          const response = await api.get(`/public/clube/rescues/${billId}`);
          setRescues(response.data);
        } catch (erre) {
          console.log(erre);
        }
      }
    }
    try {
      getClube();
    } catch (err) {
      console.log(err);
    }
  }, [restaurantId]);

  const [cashbackRescued, setCashbackRescued] = useState('0.0');

  useEffect(() => {
    async function getRescues() {
      const response = await api.get(`/public/clube/rescues/${billId}`);
      setRescues(response.data);
      setCashbackRescued(response.data[0] && response.data[0].cashback);
    }
    if (clube) {
      try {
        getRescues();
      } catch (err) {
        console.log(err);
      }
    }
  }, [clube]);

  function handleChangeCelphone(e) {
    setCelphone(e.target.value);
    if (e.target.value.length === 15) {
      getInfoCliente(e.target.value);
    }
  }
  const [loadingClube, setLoadingClube] = useState(false);

  const getInfoCliente = useCallback(async phone => {
    setLoadingClube(true);
    try {
      let handlePhone = phone.replace(' ', '').replace('-', '');
      const response = await api.get(
        `/public/clube/client/${handlePhone}/${restaurantId}`,
      );
      setClubeClient(response.data);
    } catch (err) {
      console.log(err);
    }
    setLoadingClube(false);
  }, []);

  const [phone, setCelphone] = useState(() => {
    const authStorage = localStorage.getItem('@garcomdigital:userPhone');

    if (authStorage) {
      return authStorage;
    }

    return '';
  });

  useEffect(() => {
    if (userPhone.length > 14) {
      getInfoCliente(userPhone);
    }
  }, [userPhone]);

  const [messageBirthday, setMessageBirthday] = useState('');
  const [allowRescue, setAllowRescue] = useState(false);

  useEffect(() => {
    setRestaurantCouponCode('');
    if (userPhone.length > 14) {
      getInfoCliente(userPhone);
    }
  }, []);

  useEffect(() => {
    async function confirmBirthday() {
      try {
        setMessageBirthday('Conferindo Data de Nascimento...');
        let handlePhone = phone.replace(' ', '').replace('-', '');
        let body = {
          phone: handlePhone,
          restaurantId,
          birthday,
        };

        const response = await api.post(`/public/clube/confirm-birthday`, body);

        if (response.data.success) {
          document.getElementById('rescueBtt').disabled = false;
          setMessageBirthday('');
          setAllowRescue(true);
        } else {
          document.getElementById('rescueBtt').disabled = true;
          setMessageBirthday(response.data.error);
        }
      } catch (err) {
        console.log('err', err);
      }
    }
    if (birthday.length === 10) {
      confirmBirthday();
    } else if (birthday.length === 9) {
      // document.getElementById('rescueBtt').disabled = true;
      setAllowRescue(false);
    }
  }, [birthday]);

  function toggleModalHelperError() {
    setIsModalHelperErrorOpened(!isModalHelperErrorOpened);
  }

  function verifyTableType(type) {
    if (isCourtesy) {
      return ` ESX`;
    } else if (restaurantUseCommand.toString() === 'true' && type !== 'menu') {
      return ` Comanda ${tableNumber}`;
    } else {
      switch (type) {
        case 'menu':
          return ` Cardápio`;

        case 'table':
          return ` Mesa ${tableNumber}`;

        case 'prepaid':
          return ` Mesa ${tableNumber}`;

        case 'delivery':
          return ` Delivery`;

        case 'lounge':
          return ` Lounge ${tableNumber}`;
      }
    }
  }

  async function handleAskedForWaiterHelp(data) {
    try {
      if (formRef.current) {
        formRef.current.setErrors({});
      }

      if (!userPhone) {
        const schema = Yup.object().shape({
          phone: Yup.string().required('Telefone obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
      }

      if (restaurantUseCommand) {
        const schema = Yup.object().shape({
          command_table_number: Yup.string().required('Mesa obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
      }

      await api.post('/public/help-requests', {
        name: userName || data.name,
        phone: userPhone || data.phone,
        table_id: tableId,
        comment: data.comment,
        command_table_number: data.command_table_number || null,
      });

      toggleModalWaiterHelp();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formRef.current) {
          formRef.current.setErrors(errors);
        }
      } else {
        alert('erro ao solicitar ajuda');
      }
      console.log(error);
    }
  }

  //menu translations
  const [menuLanguage, setMenuLanguage] = useState(
    menuTranslationsLanguage.toString(),
  );

  useEffect(() => {
    setMenuLanguage(menuTranslationsLanguage.toString());
  }, [menuTranslationsLanguage]);

  //pixel_fb
  function handleSendDataToPixel(category, item) {
    const aleatorID = uuid();
    if (restaurantPixelId && tableType !== 'delivery') {
      fbq(
        'track',
        'ViewContent',
        {
          content_name: item.name,
          content_category: category.name,
          content_ids: item.id,
          content_type: 'produto',
        },
        {
          eventID: aleatorID,
        },
      );
    }

    if (restaurantPixelId2 && tableType === 'delivery') {
      fbq(
        'track',
        'ViewContent',
        {
          content_name: item.name,
          content_category: category.name,
          content_ids: item.id,
          content_type: 'produto',
        },
        {
          eventID: aleatorID,
        },
      );
    }

    if (restaurantPixelId && restaurantMetaToken && tableType !== 'delivery') {
      api.post(
        `https://graph.facebook.com/v19.0/${restaurantPixelId}/events?=${restaurantMetaToken}`,
        {
          pixel_id: restaurantPixelId,
          access_token: restaurantMetaToken,
          data: [
            {
              event_name: 'ViewContent',
              event_id: aleatorID,
              event_time: Math.floor(Date.now() / 1000),
              action_source: 'website',
              user_data: {
                client_user_agent: navigator.userAgent,
                subscription_id: subscriptionIdPixel,
              },
              custom_data: {
                content_name: item.name,
                content_category: category.name,
                content_ids: item.id,
                content_type: 'produto',
              },
            },
          ],
          partner_agent: 'Takeat',
        },
      );
    }

    if (
      restaurantPixelId2 &&
      restaurantMetaToken2 &&
      tableType === 'delivery'
    ) {
      api.post(
        `https://graph.facebook.com/v19.0/${restaurantPixelId2}/events?=${restaurantMetaToken2}`,
        {
          pixel_id: restaurantPixelId2,
          access_token: restaurantMetaToken2,
          data: [
            {
              event_name: 'ViewContent',
              event_id: aleatorID,
              event_time: Math.floor(Date.now() / 1000),
              action_source: 'website',
              user_data: {
                client_user_agent: navigator.userAgent,
                subscription_id: subscriptionIdPixel2,
              },
              custom_data: {
                content_name: item.name,
                content_category: category.name,
                content_ids: item.id,
                content_type: 'produto',
              },
            },
          ],
          partner_agent: 'Takeat',
        },
      );
    }
  }

  //rating without buy
  const [isModalRegisterOpened, setIsModalRegisterOpened] = useState(false);
  const registerRef = useRef(null);

  const handleRate = useCallback(() => {
    history.push('/app/review', { from: 'menu' });
  }, []);

  function toggleModalRegister() {
    setIsModalRegisterOpened(!isModalRegisterOpened);
  }

  async function handleRegister(data) {
    try {
      if (registerRef.current) {
        registerRef.current.setErrors({});
      }

      const schema = Yup.object().shape({
        phone: Yup.string().required('Telefone obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      setUser(data.phone, data.name);
      userLogin(data.phone, data.name);

      history.push('/app/restaurant-rating');

      toggleModalRegister();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (registerRef.current) {
          registerRef.current.setErrors(errors);
        }
      } else {
        alert('erro ao enviar dados');
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', checkCategoryVisible);
    return () => {
      window.removeEventListener('scroll', checkCategoryVisible);
    };
  }, []);

  const [isModalRestaurantInfoOpened, setIsModalRestaurantInfoOpened] = useState(false)
  function toggleModalRestauranteInfo() {
    setIsModalRestaurantInfoOpened(!isModalRestaurantInfoOpened)
  }

  useEffect(() => {
    setIsMenuVisible(false)
  }, [])

  return tableType === 'delivery' ? (
    loadingMenu ? (
      <LoadingPage duration={3} text="Carregando o cardápio..." />
    ) : (
      <Container>
        <Content redirectType={redirectType}>
          <ModalStrap
            isOpen={modalProductIsOpen}
            toggle={toggleModalProduct}
            style={{ height: '100%', background: '#fff', borderRadius: '0' }}

          // onRequestClose={closeProductModal}
          // style={customModalProductStyles}
          >
            <Product
              item={itemProduct}
              closeProductModal={closeProductModal}
              menuTranslationsEnabled={menuTranslationsEnabled}
              menuLanguage={menuLanguage}
            />
          </ModalStrap>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Descrição do Item"
          >
            <ModalTitle>
              <p>{modalPhrase}</p>
            </ModalTitle>
            <ModalFooter>
              <ConfirmButton onClick={closeModal}>Ok, entendi</ConfirmButton>
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={modalErrorOpen}
            onRequestClose={closeModalError}
            style={customStyles}
            contentLabel="Descrição do Item"
          >
            <ModalTitle>
              <p>{modalPhrase}</p>
            </ModalTitle>
            <ModalFooter>
              <ConfirmButton onClick={closeModalError}>
                Ok, entendi
              </ConfirmButton>
            </ModalFooter>
          </Modal>


          {redirectType.toString() !== "delivery" ? (
            <HeaderAction>
              <header>
                <h1>{restaurantFantasyName}</h1>
                <div>
                  <FiCoffee />
                  {verifyTableType(tableType)}
                </div>
              </header>

              {menuTranslationsEnabled.toString() === 'true' && (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <FlagsArea menu />
                </div>
              )}
              <MenuScrollNav
                menu={menu}
                handleExpandCategory={handleExpandCategory}
                tableType={tableType}
                menuTranslationsEnabled={menuTranslationsEnabled}
                menuLanguage={menuLanguage}
              />
            </HeaderAction>
          ) : (
            <DeliveryHeader>
              <DeliveryHeaderItemInfo>
                <div style={{ display: "flex", justifyContent: "space-between", position: "relative", zIndex: 100 }} onClick={toggleModalRestauranteInfo}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {restaurantAvatar && (
                      <Color
                        src={restaurantAvatar}
                        crossOrigin="anonymous"
                        format="hex"
                      >
                        {({ data }) => (
                          <RestaurantLogo style={{ backgroundColor: data || '#fff' }}>
                            <img src={restaurantAvatar} alt="logo" />
                          </RestaurantLogo>
                        )}
                      </Color>
                    )}
                    <RestaurantName>
                      {restaurantFantasyName}
                    </RestaurantName>
                  </div>

                  <span onClick={toggleModalRestauranteInfo} style={{ width: 40, textAlign: "right" }}>
                    <IoIosArrowForward color='#FE1933' />
                  </span>

                  <ModalRestaurantInfo
                    isOpen={isModalRestaurantInfoOpened}
                  >
                    <FiX
                      color='#FE1933'
                      onClick={toggleModalRestauranteInfo}
                      style={{
                        position: "absolute",
                        right: 8,
                        top: 8
                      }}
                    />
                    {restaurantGreetinMessage && (
                      <>
                        <p style={{
                          fontSize: 16, fontWeight: "bold",
                          display: "flex", justifyContent: "space-between"
                        }}>
                          Descrição
                        </p>
                        <span style={{ fontSize: 14, marginBottom: 8 }}>
                          {restaurantGreetinMessage}
                        </span>
                      </>
                    )}

                    {
                      (restaurantWithdrawalActive.toString() === 'true' &&
                        restaurantDeliveryActive.toString() === 'true') && (
                        <StatusRestaurantInfo active={true} modal>
                          <FaCircle color='#2EC9B7' size={7} /> Aberto
                        </StatusRestaurantInfo>
                      )
                    }

                    {
                      (restaurantWithdrawalActive.toString() === 'true' &&
                        restaurantDeliveryActive.toString() === 'false') && (
                        <StatusRestaurantInfo active={true} modal>
                          <FaCircle color='#2EC9B7' size={7} /> Somente retirada aberto
                        </StatusRestaurantInfo>
                      )
                    }

                    {
                      (restaurantWithdrawalActive.toString() === 'false' &&
                        restaurantDeliveryActive.toString() === 'true') && (
                        <StatusRestaurantInfo active={true} modal>
                          <FaCircle color='#2EC9B7' size={7} /> Somente delivery aberto
                        </StatusRestaurantInfo>
                      )
                    }

                    {
                      (restaurantWithdrawalActive.toString() === 'false' &&
                        restaurantDeliveryActive.toString() === 'false') && (
                        <StatusRestaurantInfo active="false" modal>
                          <FaCircle color='#FE1933' size={7} /> Fechado
                        </StatusRestaurantInfo>
                      )
                    }

                    <hr />
                    {restaurantAddress && (
                      <>
                        <p style={{ fontSize: 14, fontWeight: "bold" }}>
                          <FaMapMarkerAlt /> Localização
                        </p>
                        <p style={{ display: "flex", flexDirection: "column", fontSize: 14 }}>
                          <span>
                            {`${restaurantAddress?.street}, ${restaurantAddress?.number} - ${restaurantAddress?.neighborhood}`}
                          </span>
                          <span>
                            {`${restaurantAddress?.city} - ${restaurantAddress?.state}`}
                          </span>
                          <span>{restaurantAddress?.zip_code}</span>

                        </p>
                      </>
                    )}

                    {(restaurantWithdrawalActive.toString() === "true" || restaurantDeliveryActive.toString() === "true")
                      && (restaurantDeliveryMinimumPrice > 0 || restaurantWithdrawallMinimumPrice > 0) && (
                        <p style={{ fontSize: 14, fontWeight: "bold" }}>
                          $ Pedido mínimo:
                        </p>
                      )}

                    {restaurantAcceptsDelivery.toString() === "true" &&
                      restaurantDeliveryActive.toString() === "true" &&
                      restaurantAcceptsWithdrawal.toString() === "true" &&
                      restaurantWithdrawalActive.toString() === "true" && (
                        <span style={{ fontSize: 10, display: "flex", width: 260 }}>
                          <StatusRestaurantInfo modal>
                            <FaCircle color='#2EC9B7' size={7} />
                            {restaurantDeliveryMinimumPrice > 0 ?
                              (`Delivery : R$ ${restaurantDeliveryMinimumPrice} |`) : (
                                `Delivery |`
                              )}
                          </StatusRestaurantInfo>
                          <StatusRestaurantInfo modal>
                            <FaCircle color='#2EC9B7' size={7} style={{ marginLeft: 4 }} />
                            Retirada {restaurantWithdrawallMinimumPrice > 0 &&
                              (`R$ ${restaurantWithdrawallMinimumPrice}`)}
                          </StatusRestaurantInfo>
                        </span>
                      )}

                    {restaurantAcceptsDelivery.toString() === "true" &&
                      restaurantDeliveryActive.toString() === "false" &&
                      restaurantAcceptsWithdrawal.toString() === "true" &&
                      restaurantWithdrawalActive.toString() === "true" && (
                        <span style={{ fontSize: 10, display: "flex", width: 260 }}>
                          <StatusRestaurantInfo modal>
                            <FaCircle color='#FE1933' size={7} />
                            {restaurantDeliveryMinimumPrice > 0 ?
                              (`Delivery : R$ ${restaurantDeliveryMinimumPrice} |`) : (
                                `Delivery |`
                              )}
                          </StatusRestaurantInfo>
                          <StatusRestaurantInfo modal>
                            <FaCircle color='#2EC9B7' size={7} style={{ marginLeft: 4 }} />
                            Retirada {restaurantWithdrawallMinimumPrice > 0 && (`: R$ ${restaurantWithdrawallMinimumPrice}`)}
                          </StatusRestaurantInfo>
                        </span>
                      )}

                    {restaurantAcceptsDelivery.toString() === "true" &&
                      restaurantDeliveryActive.toString() === "true" &&
                      restaurantAcceptsWithdrawal.toString() === "true" &&
                      restaurantWithdrawalActive.toString() === "false" && (
                        <span style={{ fontSize: 10, display: "flex", width: 260 }}>
                          <StatusRestaurantInfo modal>
                            <FaCircle color='#2EC9B7' size={7} />
                            {restaurantDeliveryMinimumPrice > 0 ?
                              (`Delivery : R$ ${restaurantDeliveryMinimumPrice} |`) : (
                                `Delivery |`
                              )}
                          </StatusRestaurantInfo>
                          <StatusRestaurantInfo modal>
                            <FaCircle color='#FE1933' size={7} style={{ marginLeft: 4 }} />
                            Retirada {restaurantWithdrawallMinimumPrice > 0 && (`: R$ ${restaurantWithdrawallMinimumPrice}`)}
                          </StatusRestaurantInfo>
                        </span>
                      )}

                    {restaurantAcceptsDelivery.toString() === "true" && restaurantDeliveryActive.toString() === "true" &&
                      restaurantDeliveryMinimumPrice > 0 && restaurantAcceptsWithdrawal.toString() === "false" && (
                        <span style={{ fontSize: 12 }}>
                          Somente delivery: R$ {restaurantDeliveryMinimumPrice}
                        </span>
                      )}

                    {restaurantAcceptsDelivery.toString() === "false" && restaurantAcceptsWithdrawal.toString() === "true" &&
                      restaurantWithdrawallMinimumPrice > 0 && (
                        <span style={{ fontSize: 12 }}>
                          Somente retirada: R$ {restaurantWithdrawallMinimumPrice}
                        </span>
                      )}

                  </ModalRestaurantInfo>
                </div>

                <hr style={{ color: "#33333340" }} />
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>

                    {(restaurantWithdrawalActive.toString() === "true" || restaurantDeliveryActive.toString() === "true")
                      && (restaurantDeliveryMinimumPrice > 0 || restaurantWithdrawallMinimumPrice > 0) && (
                        <span style={{ fontSize: 12, fontWeight: "bold" }}>
                          $ Pedido mínimo:
                        </span>
                      )}

                    {restaurantAcceptsDelivery.toString() === "true" &&
                      restaurantDeliveryActive.toString() === "true" &&
                      restaurantAcceptsWithdrawal.toString() === "true" &&
                      restaurantWithdrawalActive.toString() === "true" && (
                        <span style={{ fontSize: 10, display: "flex", width: 222 }}>
                          <StatusRestaurantInfo>
                            <FaCircle color='#2EC9B7' size={7} />
                            {restaurantDeliveryMinimumPrice > 0 ?
                              (`Delivery : R$ ${restaurantDeliveryMinimumPrice} |`) : (
                                `Delivery |`
                              )}
                          </StatusRestaurantInfo>
                          <StatusRestaurantInfo>
                            <FaCircle color='#2EC9B7' size={7} style={{ marginLeft: 4 }} />
                            Retirada {restaurantWithdrawallMinimumPrice > 0 &&
                              (`R$ ${restaurantWithdrawallMinimumPrice}`)}
                          </StatusRestaurantInfo>
                        </span>
                      )}

                    {restaurantAcceptsDelivery.toString() === "true" &&
                      restaurantDeliveryActive.toString() === "false" &&
                      restaurantAcceptsWithdrawal.toString() === "true" &&
                      restaurantWithdrawalActive.toString() === "true" && (
                        <span style={{ fontSize: 10, display: "flex", width: 222 }}>
                          <StatusRestaurantInfo>
                            <FaCircle color='#FE1933' size={7} />
                            {restaurantDeliveryMinimumPrice > 0 ?
                              (`Delivery : R$ ${restaurantDeliveryMinimumPrice} |`) : (
                                `Delivery |`
                              )}
                          </StatusRestaurantInfo>
                          <StatusRestaurantInfo>
                            <FaCircle color='#2EC9B7' size={7} style={{ marginLeft: 4 }} />
                            Retirada {restaurantWithdrawallMinimumPrice > 0 && (`: R$ ${restaurantWithdrawallMinimumPrice}`)}
                          </StatusRestaurantInfo>
                        </span>
                      )}

                    {restaurantAcceptsDelivery.toString() === "true" &&
                      restaurantDeliveryActive.toString() === "true" &&
                      restaurantAcceptsWithdrawal.toString() === "true" &&
                      restaurantWithdrawalActive.toString() === "false" && (
                        <span style={{ fontSize: 10, display: "flex", width: 222 }}>
                          <StatusRestaurantInfo>
                            <FaCircle color='#2EC9B7' size={7} />
                            {restaurantDeliveryMinimumPrice > 0 ?
                              (`Delivery : R$ ${restaurantDeliveryMinimumPrice} |`) : (
                                `Delivery |`
                              )}
                          </StatusRestaurantInfo>
                          <StatusRestaurantInfo>
                            <FaCircle color='#FE1933' size={7} style={{ marginLeft: 4 }} />
                            Retirada {restaurantWithdrawallMinimumPrice > 0 && (`: R$ ${restaurantWithdrawallMinimumPrice}`)}
                          </StatusRestaurantInfo>
                        </span>
                      )}

                    {restaurantAcceptsDelivery.toString() === "true" && restaurantDeliveryActive.toString() === "true" &&
                      restaurantDeliveryMinimumPrice > 0 && restaurantAcceptsWithdrawal.toString() === "false" && (
                        <span style={{ fontSize: 10 }}>
                          Somente delivery: R$ {restaurantDeliveryMinimumPrice}
                        </span>
                      )}

                    {restaurantAcceptsDelivery.toString() === "false" && restaurantAcceptsWithdrawal.toString() === "true" &&
                      restaurantWithdrawallMinimumPrice > 0 && (
                        <span style={{ fontSize: 10 }}>
                          Somente retirada: R$ {restaurantWithdrawallMinimumPrice}
                        </span>
                      )}

                    {
                      (restaurantWithdrawalActive.toString() === 'true' &&
                        restaurantDeliveryActive.toString() === 'true') && (
                        <StatusRestaurantInfo active={true}>
                          <FaCircle color='#2EC9B7' size={7} /> Aberto
                        </StatusRestaurantInfo>
                      )
                    }

                    {
                      (restaurantWithdrawalActive.toString() === 'true' &&
                        restaurantDeliveryActive.toString() === 'false') && (
                        <StatusRestaurantInfo active={true}>
                          <FaCircle color='#2EC9B7' size={7} /> Somente retirada aberto
                        </StatusRestaurantInfo>
                      )
                    }

                    {
                      (restaurantWithdrawalActive.toString() === 'false' &&
                        restaurantDeliveryActive.toString() === 'true') && (
                        <StatusRestaurantInfo active={true}>
                          <FaCircle color='#2EC9B7' size={7} /> Somente delivery aberto
                        </StatusRestaurantInfo>
                      )
                    }

                    {
                      (restaurantWithdrawalActive.toString() === 'false' &&
                        restaurantDeliveryActive.toString() === 'false') && (
                        <StatusRestaurantInfo active="false">
                          <FaCircle color='#FE1933' size={7} /> Fechado
                        </StatusRestaurantInfo>
                      )
                    }

                  </div>
                  {menuTranslationsEnabled.toString() === 'true' && (
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <FlagsArea menu />
                    </div>
                  )}
                </div>
              </DeliveryHeaderItemInfo>

              <MenuScrollNav
                menu={menu}
                handleExpandCategory={handleExpandCategory}
                tableType={tableType}
                menuTranslationsEnabled={menuTranslationsEnabled}
                menuLanguage={menuLanguage}
              />

              <MenuScrollNavFixed
                menu={menu}
                handleExpandCategory={handleExpandCategory}
                tableType={tableType}
                menuTranslationsEnabled={menuTranslationsEnabled}
                menuLanguage={menuLanguage}
              />
            </DeliveryHeader>
          )}

          <MenuContent
            conditionToMarginTop={restaurantFantasyName.length}
            menuTranslationsEnabled={menuTranslationsEnabled}
            redirectType={redirectType}
          >
            {menu
              .filter(cat => cat.available_in_delivery && !cat.is_exclusive)
              .map(category => (
                <React.Fragment key={category.id}>
                  {category.products.length >= 1 && (
                    <Accordion allowZeroExpanded>
                      <AccordionItem>
                        <AccordionItemButton className={category.name}>
                          <MenuItemHeader
                            name={category.name}
                            style={{
                              backgroundImage: `linear-gradient(to right, rgba(0,0,0,.8), rgba(255,255,255,0)), url(${getCategoryImage(category) || Shape
                                })`,
                              backgroundSize: 'cover',
                              backgroundPosition: 'center',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              {/* <FiChevronDown size={10} color="#333" /> */}
                              <strong>
                                {menuTranslationsEnabled &&
                                  menuLanguage === 'english'
                                  ? category?.translations?.en?.name
                                    ? category?.translations?.en?.name
                                    : category.name
                                  : category.name}
                              </strong>
                              {category?.preparation_time > 0 && (
                                <span>
                                  <FaClock />
                                  <span style={{ fontSize: '12px' }}>
                                    {`${category?.preparation_time} min.`}{' '}
                                  </span>
                                </span>
                              )}
                            </div>
                          </MenuItemHeader>
                        </AccordionItemButton>

                        {category.products
                          .filter(product => product.available_in_delivery)
                          .map((item, index) => (
                            <AccordionItemPanel key={index}>
                              <MenuItemBody key={item.id}>
                                <AddToCart
                                  disable={item.sold_off.toString()}
                                  onClick={() => {
                                    setUserRecentlyProduct(item);
                                    openProductModal(item);
                                    handleSendDataToPixel(category, item);
                                  }}
                                >
                                  <div>
                                    {item.image && (
                                      <LazyLoadImage
                                        src={
                                          item.image.url_thumb || item.image.url
                                        }
                                        alt={item.nome || 'foto'}
                                        width={75}
                                        height={65}
                                        PlaceholderSrc={
                                          item.image.url_thumb || item.image.url
                                        }
                                        effect="blur"
                                        style={{ objectFit: 'cover' }}
                                      />
                                    )}

                                    <MenuItemDescription
                                      disable={item.sold_off.toString()}
                                    >
                                      <strong>
                                        {menuTranslationsEnabled &&
                                          menuLanguage === 'english'
                                          ? item?.translations?.en?.name
                                            ? item?.translations?.en?.name
                                            : item.name
                                          : item.name}
                                      </strong>
                                      <p>
                                        {menuTranslationsEnabled &&
                                          menuLanguage === 'english'
                                          ? item?.translations?.en?.description
                                            ? item?.translations?.en
                                              ?.description
                                            : item.description
                                          : item.description}
                                      </p>
                                    </MenuItemDescription>
                                  </div>

                                  <MenuItemPrice>
                                    <PriceTag
                                      price={item.delivery_price || item.price}
                                      price_promotion={
                                        item.delivery_price_promotion
                                      }
                                      has_starting_price={
                                        item.has_starting_price
                                      }
                                      minimum_complements={startingPrice(
                                        item.complement_categories,
                                        true,
                                      )}
                                    />

                                    {item.promotion && (
                                      <Badge>
                                        {menuTranslationsEnabled &&
                                          menuLanguage === 'english'
                                          ? item?.translations?.en?.promotion
                                            ? item?.translations?.en?.promotion
                                            : item.promotion
                                          : item.promotion}
                                      </Badge>
                                    )}
                                    {item.sold_off && (
                                      <SoldOff>Esgotado</SoldOff>
                                    )}
                                  </MenuItemPrice>
                                </AddToCart>
                              </MenuItemBody>
                            </AccordionItemPanel>
                          ))}
                      </AccordionItem>
                    </Accordion>
                  )}
                </React.Fragment>
              ))}
          </MenuContent>

          {(restaurantWithdrawalActive.toString() === 'true' ||
            restaurantDeliveryActive.toString() === 'true' ||
            orderSchedulingActive.toString() === 'true') && (
              <Footer>
                {showHasOrderAdvice && (
                  <HasOrdersAdvice onClick={toggleHasOrderAdvice}>
                    Acompanhe seus pedidos aqui!
                    <FiX
                      size={22}
                      color="var(--primary)"
                      onClick={toggleHasOrderAdvice}
                    />
                  </HasOrdersAdvice>
                )}

                <div>
                  {hasFoodpark.toString() === 'true' ? (
                    <ButtonGoToAccount onClick={openFoodpark}>
                      <IoMdArrowBack size={22} color="var(--primary)" />
                      Foodpark
                    </ButtonGoToAccount>
                  ) : (
                    <button
                      type="button"
                      style={{ color: '#3d3d3d', fontWeight: 500 }}
                    >
                      <FiHelpCircle
                        size={22}
                        color="var(--primary)"
                        onClick={handleOpenWhatspp}
                      />
                      Ajuda
                    </button>
                  )}

                  <ButtonGoToAccount onClick={() => openInstagram()}>
                    <AiOutlineInstagram size={22} color="var(--primary)" />
                    Siga-nos
                  </ButtonGoToAccount>

                  <ButtonAction>
                    <ButtonGoToCart onClick={handleGoToCart}>
                      {/* <FlagIcon>
                    <img src={Bandeirinhas} alt="imagem de bandeirinhas de festa junina"   style={{ width: 47 }}/>
                  </FlagIcon> */}
                      <img
                        src={logo_icon}
                        alt="logo da takeat"
                        style={{ width: 32 }}
                      />
                      {!!cart.length > 0 && <CountBadge>{cartAmount}</CountBadge>}
                    </ButtonGoToCart>
                    <span>Pedir</span>
                  </ButtonAction>

                  <ButtonGoToAccount
                    onClick={() => {
                      if (!sessionKey) {
                        setShowModal('no-session');
                        return;
                      }
                      if (!clube) {
                        setShowModal('no-clube');
                        return;
                      }
                      if (!clubeClient.clientExist) {
                        setShowModal('new-client');
                        return;
                      }
                      if (!clubeClient.clientBelongsToStore) {
                        setShowModal('login');
                        return;
                      }

                      setShowModal('rescue');
                    }}
                  >
                    {/* <div style={{ position: 'absolute', paddingBottom: 45 }}>
                    <NewBadge>Novo!</NewBadge>
                  </div> */}
                    <TiTicket size={22} color="var(--primary)" />
                    Cashback
                  </ButtonGoToAccount>

                  <ButtonGoToAccount onClick={handleGoToOrders}>
                    <TiClipboard size={22} color="var(--primary)" />
                    Pedidos
                  </ButtonGoToAccount>
                </div>
              </Footer>
            )}
          {clube ? (
            <Modal
              isOpen={modalClubeIsOpen}
              onRequestClose={closeClubeModal}
              style={customStyles}
              contentLabel="Descrição do Item"
            >
              <ModalTitle>
                <p style={{ textAlign: 'center' }}>Clube do {clube.name}</p>
              </ModalTitle>
              <ModalBody>
                {sessionKey &&
                  (phone.length > 14 ? (
                    loadingClube ? (
                      <>
                        <p>Carregando...</p>
                      </>
                    ) : (
                      <>
                        <h4>{phone}</h4>
                        {!clubeClient.clientExist && (
                          <>
                            <p style={{ fontSize: 16, color: 'red' }}>
                              Poxa! Você ainda não é cadastrado no clube :(
                            </p>
                            <RescueButton onClick={() => openClubeCadastro()}>
                              <strong>
                                Finalize seu cadastro e ganhe R$
                                {parseFloat(
                                  clubeClient.totalClientCashback,
                                ).toLocaleString('pt-br', {
                                  minimumFractionDigits: 2,
                                })}
                              </strong>
                            </RescueButton>
                          </>
                        )}
                        {clubeClient.clientExist &&
                          !clubeClient.clientBelongsToStore && (
                            <>
                              <p style={{ fontSize: 16, color: 'red' }}>
                                Você ainda não participa do Clube do{' '}
                                {clube.name}
                              </p>
                              <RescueButton onClick={() => openClubeCadastro()}>
                                <strong>
                                  Clique aqui para participar e ganhar R$
                                  {parseFloat(
                                    clubeClient.totalClientCashback,
                                  ).toLocaleString('pt-br', {
                                    minimumFractionDigits: 2,
                                  })}
                                </strong>
                              </RescueButton>
                            </>
                          )}
                        {clubeClient.clientExist &&
                          clubeClient.clientBelongsToStore &&
                          (rescues.length === 0 ? (
                            <>
                              <p style={{ fontSize: 16 }}>
                                Olá! Você tem de cashback:
                              </p>
                              <h5>
                                R$
                                {parseFloat(
                                  clubeClient?.totalClientCashback,
                                ).toLocaleString('pt-br', {
                                  minimumFractionDigits: 2,
                                })}
                              </h5>
                              {parseFloat(clubeClient.totalClientCashback) >
                                parseFloat(totalTable?.total_service_price) ? (
                                <RescueButton id="rescueBtt" disabled>
                                  <strong>
                                    O consumo (R$
                                    {parseFloat(
                                      parseFloat(
                                        totalTable.total_service_price,
                                      ).toFixed(2),
                                    ).toLocaleString('pt-br', {
                                      minimumFractionDigits: 2,
                                    })}
                                    ) deve ser maior/igual seu cashback
                                  </strong>
                                </RescueButton>
                              ) : clube.settings.minimo === null ||
                                parseFloat(clubeClient.totalClientCashback) >=
                                parseFloat(clube.settings.minimo) ? (
                                <>
                                  <div>
                                    Data de Nascimento:
                                    <InputMask
                                      mask="99/99/9999"
                                      maskChar=""
                                      placeholder="dd/mm/aaaa"
                                      value={birthday}
                                      onChange={e =>
                                        setBirthday(e.target.value)
                                      }
                                    />
                                  </div>
                                  {messageBirthday ===
                                    'Conferindo Data de Nascimento...' ? (
                                    <p style={{ color: 'grey' }}>
                                      {messageBirthday}
                                    </p>
                                  ) : (
                                    <p style={{ color: 'red' }}>
                                      {messageBirthday}
                                    </p>
                                  )}
                                  {allowRescue ? (
                                    <RescueButton
                                      id="rescueBtt"
                                      onClick={() => {
                                        if (
                                          clube.settings.minimo === null ||
                                          clubeClient.totalClientCashback >=
                                          parseFloat(clube.settings.minimo)
                                        ) {
                                          handleRescue({
                                            cashback:
                                              clubeClient.totalClientCashback,
                                          });
                                        } else {
                                          toast.error(
                                            `Resgate abaixo do mínimo permitido (R$${parseFloat(
                                              clube.settings.minimo,
                                            )})`,
                                          );
                                        }
                                      }}
                                    >
                                      <strong>Resgate Agora!</strong>
                                    </RescueButton>
                                  ) : (
                                    <RescueButton id="rescueBtt" disabled>
                                      <strong>{buttonMessage}</strong>
                                    </RescueButton>
                                  )}
                                </>
                              ) : (
                                <RescueButton disabled>
                                  <strong>
                                    O valor mínimo de resgate é R$
                                    {parseFloat(
                                      parseFloat(clube.settings.minimo).toFixed(
                                        2,
                                      ),
                                    ).toLocaleString('pt-br', {
                                      minimumFractionDigits: 2,
                                    })}
                                  </strong>
                                </RescueButton>
                              )}

                              <RefuseButton onClick={() => closeClubeModal()}>
                                Deixar para outro dia
                              </RefuseButton>
                            </>
                          ) : (
                            <>
                              <h5 style={{ marginTop: 5 }}>
                                R$
                                {parseFloat(cashbackRescued).toLocaleString(
                                  'pt-br',
                                  {
                                    minimumFractionDigits: 2,
                                  },
                                )}
                              </h5>
                              <p style={{ fontSize: 16, color: 'green' }}>
                                Resgatado com sucesso!
                              </p>
                              <RescueButton onClick={() => closeClubeModal()}>
                                Ok
                              </RescueButton>
                            </>
                          ))}
                      </>
                    )
                  ) : (
                    <>
                      <p>Digite seu telefone:</p>
                      <InputMask
                        mask="(99) 99999-9999"
                        maskChar=""
                        placeholder="xx xxxx-xxxx"
                        value={phone}
                        onChange={e => handleChangeCelphone(e)}
                        style={{ marginTop: 2, marginBottom: 5 }}
                      />
                      <p>
                        O Clube do {clube.name} te dá {clube.settings.percent}%
                        de cashback do seu consumo para uso na sua próxima
                        visita!
                      </p>
                    </>
                  ))}
                {!sessionKey && <p>Volte aqui depois de fazer um pedido :)</p>}
              </ModalBody>
              {!sessionKey && (
                <ModalFooter>
                  <ConfirmButton onClick={closeClubeModal}>
                    Ok, entendi
                  </ConfirmButton>
                </ModalFooter>
              )}
            </Modal>
          ) : (
            <Modal
              isOpen={modalClubeIsOpen}
              onRequestClose={closeClubeModal}
              style={customStyles}
              contentLabel="Descrição do Item"
            >
              <ModalTitle>
                <p style={{ textAlign: 'center' }}>
                  Esse estabelecimento ainda não implementou o programa de
                  cashback/pontos
                </p>
              </ModalTitle>
              <ModalFooter>
                <ConfirmButton onClick={closeClubeModal}>
                  Ok, entendi
                </ConfirmButton>
              </ModalFooter>
            </Modal>
          )}
        </Content>
      </Container>
    )
  ) : loadingMenu ? (
    <LoadingPage duration={3} text="Carregando o cardápio..." />
  ) : (
    <Container>
      <Content redirectType={redirectType}>
        <ModalStrap isOpen={isModalRegisterOpened} toggle={toggleModalRegister}>
          <ModalHeaderStrap>Informe seus dados:</ModalHeaderStrap>
          <Form onSubmit={handleRegister} ref={registerRef}>
            <ModalBodyStrap>
              <InputMask mask="(99) 99999-9999" maskChar="">
                <Input name="phone" placeholder="Celular" type="text" />
              </InputMask>
              <Input placeholder="Nome" name="name" />
            </ModalBodyStrap>
            <ModalFooter style={{ padding: 20 }}>
              <Button
                title="Cancelar"
                type="button"
                inverted
                onClick={toggleModalRegister}
              />
              <Button title="Enviar" type="submit" style={{ marginLeft: 8 }} />
            </ModalFooter>
          </Form>
        </ModalStrap>

        <ModalStrap
          isOpen={modalProductIsOpen}
          toggle={toggleModalProduct}
          style={{ height: '100%', background: '#fff', borderRadius: '0' }}

        // onRequestClose={closeProductModal}
        // style={customModalProductStyles}
        >
          <Product
            item={itemProduct}
            closeProductModal={closeProductModal}
            menuTranslationsEnabled={menuTranslationsEnabled}
            menuLanguage={menuLanguage}
          />
        </ModalStrap>

        <Modal
          isOpen={isModalPixOpened}
          onRequestClose={toggleModalPix}
          style={customStyles}
          contentLabel="Descrição do Item"
        >
          <ModalTitle>
            <p style={{ textAlign: 'center' }}>
              Sabia que agora você pode pagar com pix por aqui?
            </p>
          </ModalTitle>
          <ModalBody>
            <div>
              <p>
                É muito mais rápido, prático e fácil. Ao fechar a{' '}
                <strong>conta</strong> "<FiCheckCircle color="red" />
                ", experimente
              </p>
              <img src={Pix} alt="logo do pix" width={100} />
            </div>
          </ModalBody>
          <ModalFooter>
            <ConfirmButton
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
              onClick={toggleModalPix}
            >
              Ok, entendi
            </ConfirmButton>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Descrição do Item"
        >
          <ModalTitle>
            <p>{modalPhrase}</p>
          </ModalTitle>
          <ModalFooter>
            <ConfirmButton onClick={closeModal}>Ok, entendi</ConfirmButton>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={isModalHelperErrorOpened}
          onRequestClose={toggleModalHelperError}
          style={customStyles}
          contentLabel="Descrição do Item"
        >
          <ModalBody>Erro ao solicitar ajuda!</ModalBody>
        </Modal>

        <ModalHelp
          isOpen={isModalWaiterHelpOpened}
          toggle={toggleModalWaiterHelp}
        >
          <Form onSubmit={handleAskedForWaiterHelp} ref={formRef}>
            <ModalTitle>
              <p style={{ textAlign: 'center' }}>Solicitar ajuda ao garçom</p>
            </ModalTitle>
            {userPhone ? (
              <UserInfo>
                <span style={{ textAlign: 'center' }}>
                  {userPhone} - {userName}
                </span>
                {/* <span style={{textAlign: 'center'}}> {verifyTableType(tableType)}</span> */}
                {restaurantUseCommand && (
                  <Input
                    placeholder="Qual mesa você está?"
                    name="command_table_number"
                    maxLength={20}
                  />
                )}

                <TextArea placeholder="Qual sua dúvida?" name="comment" />
              </UserInfo>
            ) : (
              <>
                <InputMask mask="(99) 99999-9999" maskChar="">
                  <Input name="phone" placeholder="Celular" type="text" />
                </InputMask>
                <Input placeholder="Nome" name="name" />
                {restaurantUseCommand && (
                  <Input
                    placeholder="Qual mesa você está?"
                    name="command_table_number"
                    maxLength={20}
                  />
                )}
                <TextArea placeholder="Qual sua dúvida?" name="comment" />
              </>
            )}

            <ModalFooter>
              <CancelButton onClick={toggleModalWaiterHelp}>
                Cancelar
              </CancelButton>
              <ConfirmButton type="submit">Solicitar</ConfirmButton>
            </ModalFooter>
          </Form>
        </ModalHelp>

        <Modal
          isOpen={modalErrorOpen}
          onRequestClose={closeModalError}
          style={customStyles}
          contentLabel="Descrição do Item"
        >
          <ModalTitle>
            <p>{modalPhrase}</p>
          </ModalTitle>
          <ModalFooter>
            <ConfirmButton onClick={closeModalError}>Ok, entendi</ConfirmButton>
          </ModalFooter>
        </Modal>
        <HeaderAction>
          <header>
            <h1>{restaurantFantasyName}</h1>
            <div>
              <FiCoffee />
              {verifyTableType(tableType)}
            </div>
          </header>
          {/* {restaurantIsOpen.toString() === 'false' && (
            <RestaurantClosed>
              <p>Restaurante fechado</p>
            </RestaurantClosed>
          )} */}
          {menuTranslationsEnabled.toString() === 'true' && (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <FlagsArea menu />
            </div>
          )}
          <MenuScrollNav
            menu={menu}
            handleExpandCategory={handleExpandCategory}
            tableType={tableType}
            menuTranslationsEnabled={menuTranslationsEnabled}
            menuLanguage={menuLanguage}
            categoryShowing={categoryShowing}
          />
        </HeaderAction>

        <MenuContent
          conditionToMarginTop={restaurantFantasyName.length}
          menuTranslationsEnabled={menuTranslationsEnabled}
          redirectType={redirectType}
        >
          {clube &&
            !loadingClube &&
            sessionKey &&
            cashbackAdvice.toString() === 'true' &&
            rescues.length === 0 && (
              <>
                {!clubeClient.clientExist && (
                  <CashbackBanner
                    onClick={() => history.push('/club/register')}
                  >
                    <img src={bannerCoins} />
                    <CashbackBanner.Body>
                      <h2>Não perca dinheiro!</h2>
                      <p>
                        Cadastre-se agora para receber cashback em todas as
                        compras!
                      </p>
                      <button>
                        Cadastre-se{' '}
                        {parseFloat(clubeClient.totalClientCashback) > 0 &&
                          ' e ganhe R$ ' +
                          parseFloat(
                            clubeClient.totalClientCashback,
                          ).toLocaleString('pt-br', {
                            minimumFractionDigits: 2,
                          })}{' '}
                        <FaChevronRight />
                      </button>
                    </CashbackBanner.Body>
                  </CashbackBanner>
                )}

                {clubeClient.clientExist && !clubeClient.clientBelongsToStore && (
                  <CashbackBanner onClick={() => setShowModal('login')}>
                    <CashbackBanner.Body>
                      <p
                        style={{
                          fontSize: 13,
                          maxWidth: '100%',
                          fontWeight: 500,
                        }}
                      >
                        Vincule seu cadastro no clube {clube.name} e passe a
                        <b> acumular cashback</b> hoje mesmo!
                      </p>
                      <button>
                        Vincular <FaChevronRight />
                      </button>
                    </CashbackBanner.Body>
                  </CashbackBanner>
                )}

                {clubeClient.clientExist && clubeClient.clientBelongsToStore && (
                  <>
                    {parseFloat(clubeClient.totalClientCashback) > 0 ? (
                      <CashbackBanner onClick={() => setShowModal('rescue')}>
                        <img src={bannerCoins2} />
                        <CashbackBanner.Body>
                          <h2>Oba! Você possui cashback disponível:</h2>
                          <div
                            style={{
                              display: 'flex',
                              gap: 8,
                              alignItems: 'center',
                            }}
                          >
                            <h2 style={{ fontSize: 20 }}>
                              R$
                              {parseFloat(
                                clubeClient.totalClientCashback,
                              ).toLocaleString('pt-br', {
                                minimumFractionDigits: 2,
                              })}
                            </h2>
                            <button>Resgatar</button>
                          </div>
                        </CashbackBanner.Body>
                      </CashbackBanner>
                    ) : (
                      <CashbackBanner>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 8,
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              gap: 8,
                              justifyContent: 'space-between',
                            }}
                          >
                            <h2>Você já está cadastrado em nosso clube!</h2>
                            <FaRegTimesCircle
                              style={{
                                color: '#fff',
                              }}
                              size={20}
                              onClick={() => {
                                toggleButtonCashbackAdvice();
                              }}
                            />
                          </div>
                          <p style={{ maxWidth: '100%', fontSize: 12 }}>
                            Agora basta fazer suas compras para acumular
                            cashback e poder resgatar em visitas futuras!
                          </p>
                        </div>
                      </CashbackBanner>
                    )}
                  </>
                )}
              </>
            )}

          {userRecentlyProduct && (
            <RecentlyProduct
              conditionToMarginTop={restaurantFantasyName.length}
            >
              <RecentlyProductHeader>
                <p>Busca recente</p>
                <FiChevronDown size={10} color="#fff" />
              </RecentlyProductHeader>
              <AddToCart
                disable={userRecentlyProduct?.sold_off?.toString()}
                onClick={() => {
                  openProductModal(userRecentlyProduct);
                  /* handleSendDataToPixel(category, item) */
                }}
              >
                <div>
                  {userRecentlyProduct.image && (
                    <img
                      src={
                        userRecentlyProduct.image.url_thumb ||
                        userRecentlyProduct.image.url ||
                        fotoPadrao
                      }
                      alt={userRecentlyProduct.nome || 'foto'}
                      style={{ width: 75, height: 65, objectFit: 'cover' }}
                    />
                  )}

                  <MenuItemDescription
                    disable={userRecentlyProduct?.sold_off?.toString()}
                  >
                    <strong>{userRecentlyProduct.name}</strong>
                    <p>{userRecentlyProduct.description}</p>
                  </MenuItemDescription>
                </div>

                <MenuItemPrice>
                  <PriceTag
                    price={userRecentlyProduct.price}
                    price_promotion={userRecentlyProduct.price_promotion}
                    has_starting_price={userRecentlyProduct.has_starting_price}
                    minimum_complements={startingPrice(
                      userRecentlyProduct.complement_categories,
                    )}
                  />

                  {userRecentlyProduct.promotion && (
                    <Badge>{userRecentlyProduct.promotion}</Badge>
                  )}
                  {userRecentlyProduct.sold_off && <SoldOff>Esgotado</SoldOff>}
                </MenuItemPrice>
              </AddToCart>
            </RecentlyProduct>
          )}

          {tableType === 'menu' ? (
            <>
              {menu
                .filter(cat => !cat.is_exclusive)
                .map(category => (
                  <React.Fragment key={category.id}>
                    {category.products.length >= 1 && (
                      <Accordion allowZeroExpanded>
                        <AccordionItem>
                          <AccordionItemButton className={category.name}>
                            <MenuItemHeader
                              name={category.name}
                              style={{
                                backgroundImage: `linear-gradient(to right, rgba(0,0,0,.8), rgba(255,255,255,0)), url(${getCategoryImage(category) || Shape
                                  })`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  width: '100%',
                                }}
                              >
                                {/* <FiChevronDown size={10} color="#333" /> */}
                                <strong>
                                  {menuTranslationsEnabled &&
                                    menuLanguage === 'english'
                                    ? category?.translations?.en?.name
                                      ? category?.translations?.en?.name
                                      : category.name
                                    : category.name}
                                </strong>
                                <CategoryTimes>
                                  {category?.active_days && (
                                    <span
                                      style={{
                                        display: 'flex',
                                        fontSize: 10,
                                        color: '#FFA814',
                                        fontWeight: 'bold',
                                        marginLeft: -7,
                                      }}
                                    >
                                      {getActiveDays(category.active_days)}
                                    </span>
                                  )}
                                  {category?.preparation_time > 0 && (
                                    <span>
                                      <FaClock />
                                      <span style={{ fontSize: '12px' }}>
                                        {`${category?.preparation_time} min.`}{' '}
                                      </span>
                                    </span>
                                  )}
                                </CategoryTimes>

                                {category?.enable_times &&
                                  category?.start_time && (
                                    <span
                                      style={{ fontSize: 10, color: '#FFA814' }}
                                    >
                                      Das {formatTime(category?.start_time)} às{' '}
                                      {formatTime(category?.end_time)}
                                    </span>
                                  )}
                              </div>
                            </MenuItemHeader>
                          </AccordionItemButton>

                          {category.products
                            .filter(product => product.available)
                            .map((item, index) => (
                              <AccordionItemPanel key={index}>
                                <MenuItemBody key={item.id}>
                                  <AddToCart
                                    disable={item.sold_off.toString()}
                                    onClick={() => {
                                      setUserRecentlyProduct(item);
                                      openProductModal(item);
                                      handleSendDataToPixel(category, item);
                                    }}
                                  // to={{
                                  //   pathname: '/product',
                                  //   state: {
                                  //     item: {
                                  //       ...item,
                                  //       price: item.price_promotion || item.price,
                                  //     },
                                  //   },
                                  // }}
                                  >
                                    <div>
                                      {item.image && (
                                        // <img
                                        //   src={item.image.url_thumb || item.image.url || fotoPadrao}
                                        //   alt={item.nome || 'foto'}
                                        //   style={{ width: 75, height: 65 }}
                                        //   loading="lazy"
                                        // />

                                        <LazyLoadImage
                                          src={
                                            item.image.url_thumb ||
                                            item.image.url
                                          }
                                          alt={item.nome || 'foto'}
                                          width={75}
                                          height={65}
                                          PlaceholderSrc={
                                            item.image.url_thumb ||
                                            item.image.url
                                          }
                                          effect="blur"
                                          style={{ objectFit: 'cover' }}
                                        />
                                      )}

                                      <MenuItemDescription
                                        disable={item.sold_off.toString()}
                                      >
                                        <strong>
                                          {menuTranslationsEnabled &&
                                            menuLanguage === 'english'
                                            ? item?.translations?.en?.name
                                              ? item?.translations?.en?.name
                                              : item.name
                                            : item.name}
                                        </strong>
                                        <p>
                                          {menuTranslationsEnabled &&
                                            menuLanguage === 'english'
                                            ? item?.translations?.en
                                              ?.description
                                              ? item?.translations?.en
                                                ?.description
                                              : item.description
                                            : item.description}
                                        </p>
                                      </MenuItemDescription>
                                    </div>

                                    <MenuItemPrice>
                                      <PriceTag
                                        price={item.price}
                                        price_promotion={item.price_promotion}
                                        has_starting_price={
                                          item.has_starting_price
                                        }
                                        minimum_complements={startingPrice(
                                          item.complement_categories,
                                        )}
                                      />

                                      {item.promotion && (
                                        <Badge>
                                          {menuTranslationsEnabled &&
                                            menuLanguage === 'english'
                                            ? item?.translations?.en?.promotion
                                              ? item?.translations?.en
                                                ?.promotion
                                              : item.promotion
                                            : item.promotion}
                                        </Badge>
                                      )}
                                      {item.sold_off && (
                                        <SoldOff>Esgotado</SoldOff>
                                      )}
                                    </MenuItemPrice>
                                  </AddToCart>
                                </MenuItemBody>
                              </AccordionItemPanel>
                            ))}
                        </AccordionItem>
                      </Accordion>
                    )}
                  </React.Fragment>
                ))}
            </>
          ) : (
            <Accordion allowZeroExpanded allowMultipleExpanded>
              {menu
                .filter(cat => cat.available && !cat.is_exclusive)
                .map((category, idx) => (
                  <React.Fragment key={category.id}>
                    {category.products.length >= 1 && (
                      <AccordionItem>
                        <AccordionItemButton className={category.name}>
                          <MenuItemHeader
                            ref={cat => (categoryRefs.current[idx] = cat)}
                            name={category.name}
                            style={{
                              backgroundImage: `linear-gradient(to right, rgba(0,0,0,.8), rgba(255,255,255,0)), url(${getCategoryImage(category) || Shape
                                })`,
                              backgroundSize: 'cover',
                              backgroundPosition: 'center',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              {/* <FiChevronDown size={10} color="#333" /> */}
                              <strong>
                                {menuTranslationsEnabled &&
                                  menuLanguage === 'english'
                                  ? category?.translations?.en?.name
                                    ? category?.translations?.en?.name
                                    : category.name
                                  : category.name}
                              </strong>
                              {category?.preparation_time > 0 && (
                                <span>
                                  <FaClock />
                                  <span style={{ fontSize: '12px' }}>
                                    {`${category?.preparation_time} min.`}{' '}
                                  </span>
                                </span>
                              )}
                            </div>
                          </MenuItemHeader>
                        </AccordionItemButton>

                        {category.products
                          .filter(product => product.available)
                          .map((item, index) => (
                            <AccordionItemPanel key={index}>
                              <MenuItemBody key={item.id}>
                                <AddToCart
                                  disable={item.sold_off.toString()}
                                  onClick={() => {
                                    setUserRecentlyProduct(item);
                                    openProductModal(item);
                                    handleSendDataToPixel(category, item);
                                  }}
                                // to={{
                                //   pathname: '/product',
                                //   state: {
                                //     item: {
                                //       ...item,
                                //       price: item.price_promotion || item.price,
                                //     },
                                //   },
                                // }}
                                >
                                  <div>
                                    {item.image && (
                                      // <img
                                      //   src={item.image.url_thumb || item.image.url || fotoPadrao}
                                      //   alt={item.nome || 'foto'}
                                      //   style={{ width: 75, height: 65 }}
                                      //   loading="lazy"
                                      // />

                                      <LazyLoadImage
                                        src={
                                          item.image.url_thumb || item.image.url
                                        }
                                        alt={item.nome || 'foto'}
                                        width={75}
                                        height={65}
                                        PlaceholderSrc={
                                          item.image.url_thumb || item.image.url
                                        }
                                        effect="blur"
                                        style={{ objectFit: 'cover' }}
                                      />
                                    )}

                                    <MenuItemDescription
                                      disable={item.sold_off.toString()}
                                    >
                                      <strong>
                                        {menuTranslationsEnabled &&
                                          menuLanguage === 'english'
                                          ? item?.translations?.en?.name
                                            ? item?.translations?.en?.name
                                            : item.name
                                          : item.name}
                                      </strong>
                                      <p>
                                        {menuTranslationsEnabled &&
                                          menuLanguage === 'english'
                                          ? item?.translations?.en?.description
                                            ? item?.translations?.en
                                              ?.description
                                            : item.description
                                          : item.description}
                                      </p>
                                    </MenuItemDescription>
                                  </div>

                                  <MenuItemPrice>
                                    <PriceTag
                                      price={item.price}
                                      price_promotion={item.price_promotion}
                                      has_starting_price={
                                        item.has_starting_price
                                      }
                                      minimum_complements={startingPrice(
                                        item.complement_categories,
                                      )}
                                    />

                                    {item.promotion && (
                                      <Badge>
                                        {menuTranslationsEnabled &&
                                          menuLanguage === 'english'
                                          ? item?.translations?.en?.promotion
                                            ? item?.translations?.en?.promotion
                                            : item.promotion
                                          : item.promotion}
                                      </Badge>
                                    )}
                                    {item.sold_off && (
                                      <SoldOff>Esgotado</SoldOff>
                                    )}
                                  </MenuItemPrice>
                                </AddToCart>
                              </MenuItemBody>
                            </AccordionItemPanel>
                          ))}
                      </AccordionItem>
                    )}
                  </React.Fragment>
                ))}
            </Accordion>
          )}
        </MenuContent>

        {((restaurantIsOpen.toString() === 'true' &&
          tableType.toString() === 'delivery') ||
          (restaurantIsOpen.toString() === 'true' &&
            tableType !== 'menu' &&
            restaurantOnlyQrcode.toString() === 'false')) && (
            <Footer>
              <div>
                {hasFoodpark.toString() === 'true' ? (
                  <ButtonGoToAccount onClick={openFoodpark}>
                    <IoMdArrowBack size={22} color="#ff2c3a" />
                    Foodpark
                  </ButtonGoToAccount>
                ) : (
                  <button
                    type="button"
                    style={{ fontWeight: 500, color: '#3d3d3d' }}
                    onClick={toggleModalWaiterHelp}
                  >
                    <FiHelpCircle size={22} color="#ff2c3a" />
                    Ajuda
                  </button>
                )}

                <ButtonGoToAccount onClick={handleRate}>
                  <AiOutlineLike size={22} color="#ff2c3a" />
                  Avaliar
                </ButtonGoToAccount>

                <ButtonAction>
                  <ButtonGoToCart onClick={handleGoToCart}>
                    {/* <FlagIcon>
                    <img src={Bandeirinhas} alt="imagem de bandeirinhas de festa junina"   style={{ width: 47 }}/>
                  </FlagIcon> */}
                    <img
                      src={logo_icon}
                      alt="logo da takeat"
                      style={{ width: 32 }}
                    />
                    {!!cart.length > 0 && <CountBadge>{cartAmount}</CountBadge>}
                  </ButtonGoToCart>
                  <span>Pedir</span>
                </ButtonAction>

                <ButtonGoToAccount
                  onClick={() => {
                    if (!sessionKey) {
                      setShowModal('no-session');
                      return;
                    }
                    if (!clube) {
                      setShowModal('no-clube');
                      return;
                    }
                    if (!clubeClient.clientExist) {
                      setShowModal('new-client');
                      return;
                    }
                    if (!clubeClient.clientBelongsToStore) {
                      setShowModal('login');
                      return;
                    }

                    setShowModal('rescue');
                  }}
                >
                  <TiTicket size={22} color="#ff2c3a" />
                  Cashback
                </ButtonGoToAccount>

                <ButtonGoToAccount onClick={handleGoToAccount}>
                  <FiCheckCircle size={22} color="#ff2c3a" />
                  Conta
                </ButtonGoToAccount>
              </div>
            </Footer>
          )}
        {clube ? (
          <Modal
            isOpen={modalClubeIsOpen}
            onRequestClose={closeClubeModal}
            style={customStyles}
            contentLabel="Descrição do Item"
          >
            <ModalTitle>
              <p style={{ textAlign: 'center' }}>Clube do {clube.name}</p>
            </ModalTitle>
            <ModalBody>
              {sessionKey &&
                (phone.length > 14 ? (
                  loadingClube ? (
                    <>
                      <p>Carregando...</p>
                    </>
                  ) : (
                    <>
                      <h4>{phone}</h4>
                      {!clubeClient.clientExist && (
                        <>
                          <p style={{ fontSize: 16, color: 'red' }}>
                            Poxa! Você ainda não é cadastrado no clube :(
                          </p>
                          <RescueButton onClick={() => openClubeCadastro()}>
                            <strong>
                              Finalize seu cadastro e ganhe R$
                              {parseFloat(
                                clubeClient.totalClientCashback,
                              ).toLocaleString('pt-br', {
                                minimumFractionDigits: 2,
                              })}
                            </strong>
                          </RescueButton>
                        </>
                      )}
                      {clubeClient.clientExist &&
                        !clubeClient.clientBelongsToStore && (
                          <>
                            <p style={{ fontSize: 16, color: 'red' }}>
                              Você ainda não participa do Clube do {clube.name}
                            </p>
                            <RescueButton onClick={() => openClubeCadastro()}>
                              <strong>
                                Clique aqui para participar e ganhar R$
                                {parseFloat(
                                  clubeClient.totalClientCashback,
                                ).toLocaleString('pt-br', {
                                  minimumFractionDigits: 2,
                                })}
                              </strong>
                            </RescueButton>
                          </>
                        )}
                      {clubeClient.clientExist &&
                        clubeClient.clientBelongsToStore &&
                        (rescues.length === 0 ? (
                          <>
                            <p style={{ fontSize: 16 }}>
                              Olá! Você tem de cashback:
                            </p>
                            <h5>
                              R$
                              {parseFloat(
                                clubeClient.totalClientCashback,
                              ).toLocaleString('pt-br', {
                                minimumFractionDigits: 2,
                              })}
                            </h5>
                            {parseFloat(clubeClient.totalClientCashback) >
                              parseFloat(totalTable.total_service_price) ? (
                              <RescueButton id="rescueBtt" disabled>
                                <strong>
                                  O consumo (R$
                                  {parseFloat(
                                    parseFloat(
                                      totalTable.total_service_price,
                                    ).toFixed(2),
                                  ).toLocaleString('pt-br', {
                                    minimumFractionDigits: 2,
                                  })}
                                  ) deve ser maior/igual seu cashback
                                </strong>
                              </RescueButton>
                            ) : clube.settings.minimo === null ||
                              parseFloat(clubeClient.totalClientCashback) >=
                              parseFloat(clube.settings.minimo) ? (
                              <>
                                <div>
                                  Data de Nascimento:
                                  <InputMask
                                    mask="99/99/9999"
                                    maskChar=""
                                    placeholder="dd/mm/aaaa"
                                    value={birthday}
                                    onChange={e => setBirthday(e.target.value)}
                                  />
                                </div>
                                {messageBirthday ===
                                  'Conferindo Data de Nascimento...' ? (
                                  <p style={{ color: 'grey' }}>
                                    {messageBirthday}
                                  </p>
                                ) : (
                                  <p style={{ color: 'red' }}>
                                    {messageBirthday}
                                  </p>
                                )}
                                {allowRescue ? (
                                  <RescueButton
                                    id="rescueBtt"
                                    onClick={() => {
                                      if (
                                        clube.settings.minimo === null ||
                                        clubeClient.totalClientCashback >=
                                        parseFloat(clube.settings.minimo)
                                      ) {
                                        handleRescue({
                                          cashback:
                                            clubeClient.totalClientCashback,
                                        });
                                      } else {
                                        toast.error(
                                          `Resgate abaixo do mínimo permitido (R$${parseFloat(
                                            clube.settings.minimo,
                                          )})`,
                                        );
                                      }
                                    }}
                                  >
                                    <strong>Resgate Agora!</strong>
                                  </RescueButton>
                                ) : (
                                  <RescueButton id="rescueBtt" disabled>
                                    <strong>{buttonMessage}</strong>
                                  </RescueButton>
                                )}
                              </>
                            ) : (
                              <RescueButton disabled>
                                <strong>
                                  O valor mínimo de resgate é R$
                                  {parseFloat(
                                    parseFloat(clube.settings.minimo).toFixed(
                                      2,
                                    ),
                                  ).toLocaleString('pt-br', {
                                    minimumFractionDigits: 2,
                                  })}
                                </strong>
                              </RescueButton>
                            )}

                            <RefuseButton onClick={() => closeClubeModal()}>
                              Deixar para outro dia
                            </RefuseButton>
                          </>
                        ) : (
                          <>
                            <h5 style={{ marginTop: 5 }}>
                              R$
                              {parseFloat(cashbackRescued).toLocaleString(
                                'pt-br',
                                {
                                  minimumFractionDigits: 2,
                                },
                              )}
                            </h5>
                            <p style={{ fontSize: 16, color: 'green' }}>
                              Resgatado com sucesso!
                            </p>
                            <RescueButton onClick={() => closeClubeModal()}>
                              Ok
                            </RescueButton>
                          </>
                        ))}
                    </>
                  )
                ) : (
                  <>
                    <p>Digite seu telefone:</p>
                    <InputMask
                      mask="(99) 99999-9999"
                      maskChar=""
                      placeholder="xx xxxx-xxxx"
                      value={phone}
                      onChange={e => handleChangeCelphone(e)}
                      style={{ marginTop: 2, marginBottom: 5 }}
                    />
                    <p>
                      O Clube do {clube.name} te dá {clube.settings.percent}% de
                      cashback do seu consumo para uso na sua próxima visita!
                    </p>
                  </>
                ))}
              {!sessionKey && <p>Volte aqui depois de fazer um pedido :)</p>}
            </ModalBody>
            {!sessionKey && (
              <ModalFooter>
                <ConfirmButton onClick={closeClubeModal}>
                  Ok, entendi
                </ConfirmButton>
              </ModalFooter>
            )}
          </Modal>
        ) : (
          <Modal
            isOpen={modalClubeIsOpen}
            onRequestClose={closeClubeModal}
            style={customStyles}
            contentLabel="Descrição do Item"
          >
            <ModalTitle>
              <p style={{ textAlign: 'center' }}>
                Esse estabelecimento ainda não implementou o programa de
                cashback/pontos
              </p>
            </ModalTitle>
            <ModalFooter>
              <ConfirmButton onClick={closeClubeModal}>
                Ok, entendi
              </ConfirmButton>
            </ModalFooter>
          </Modal>
        )}
      </Content>

      <ModalCashback
        isOpen={['rescue', 'new-client', 'no-clube', 'no-session'].includes(
          showModal,
        )}
        toggle={() => setShowModal(null)}
      >
        {showModal === 'rescue' ? (
          <>
            <ModalBodyStrap>
              <PhoneDisplay>{userPhone}</PhoneDisplay>
              <CashbackDisplay>
                <p>Olá! Você tem de cashback:</p>
                <span>
                  R${' '}
                  {parseFloat(clubeClient?.totalClientCashback).toLocaleString(
                    'pt-br',
                    {
                      minimumFractionDigits: 2,
                    },
                  )}
                </span>
              </CashbackDisplay>
              <BirthdayInput>
                <span>Data de nascimento: </span>
                <DefaultInput
                  placeholder={
                    confirmClubRescue.state === 'blocked'
                      ? 'Já resgatou'
                      : 'dd/mm/aaaa'
                  }
                  disabled={confirmClubRescue.state === 'blocked'}
                  containerStyles={{
                    flexGrow: 1,
                    minWidth: 155,
                    width: 'min-content',
                  }}
                  mask="99/99/9999"
                  maskPlaceholder={null}
                  onChange={e => {
                    if (e.target.value.length === 10) {
                      confirmClubBirthday(e.target.value);
                    } else {
                      setConfirmClubRescue({
                        state: 'disabled',
                        message: 'Digite seu aniversário',
                      });
                    }
                  }}
                />
              </BirthdayInput>
            </ModalBodyStrap>
            <ModalFooterStrap>
              <Button
                title={confirmClubRescue.message}
                buttonColor="#2ec9b7"
                disabled={['disabled', 'blocked'].includes(
                  confirmClubRescue.state,
                )}
                onClick={() => {
                  if (clubeClient.totalClientCashback <= 0) {
                    toast.error('Você não possui cashback para resgatar');
                    return;
                  }
                  handleRescue({
                    cashback: clubeClient.totalClientCashback,
                  });
                }}
              />
            </ModalFooterStrap>
          </>
        ) : showModal === 'new-client' ? (
          <>
            <ModalBodyStrap>
              <PhoneDisplay>{userPhone}</PhoneDisplay>
              <p style={{ margin: 0, textAlign: 'center' }}>
                Você ainda não é cadastrado no clube!
              </p>
              {parseFloat(clubeClient.totalClientCashback) > 0 && (
                <p style={{ margin: 0, textAlign: 'center' }}>
                  Finalize o cadastro e ganhe{' '}
                  <span style={{ color: '#2ec9b7', fontWeight: 600 }}>
                    R${' '}
                    {parseFloat(clubeClient.totalClientCashback).toLocaleString(
                      'pt-br',
                      {
                        minimumFractionDigits: 2,
                      },
                    )}
                  </span>
                </p>
              )}
            </ModalBodyStrap>
            <ModalFooterStrap>
              <Button
                title="Finalizar cadastro"
                buttonColor="#2ec9b7"
                onClick={() => history.push('/club/register')}
              />
            </ModalFooterStrap>
          </>
        ) : showModal === 'no-clube' ? (
          <>
            <ModalBodyStrap>
              <p style={{ textAlign: 'center' }}>
                Esse estabelecimento ainda não implementou o programa de
                cashback
              </p>
            </ModalBodyStrap>
            <ModalFooterStrap>
              <Button
                title="Entendi"
                inverted
                onClick={() => setShowModal(null)}
              />
            </ModalFooterStrap>
          </>
        ) : (
          <>
            <ModalBodyStrap>
              <p style={{ textAlign: 'center' }}>
                Volte aqui depois de fazer um pedido :)
              </p>
            </ModalBodyStrap>
            <ModalFooterStrap>
              <Button
                title="Entendi"
                buttonColor="#2ec9b7"
                onClick={() => setShowModal(null)}
              />
            </ModalFooterStrap>
          </>
        )}
      </ModalCashback>

      <ModalCashback
        isOpen={showModal === 'login'}
        toggle={() => {
          setForgotPasswordModal(false);
          setShowModal(null);
        }}
      >
        {forgotPasswordModal ? (
          <>
            <ModalBodyStrap>
              <p style={{ margin: 0 }}>
                Será enviado um link de redefinição via e-mail para o endereço
                cadastrado no Clube do Cliente de telefone {userPhone}
              </p>
              <span
                style={{
                  width: '100%',
                  textAlign: 'center',
                  fontSize: 18,
                  fontWeight: 500,
                }}
              >
                {clubEmail}
              </span>
            </ModalBodyStrap>
            <div style={{ display: 'flex', gap: 8 }}>
              <Button
                title="Cancelar"
                inverted
                onClick={() => setForgotPasswordModal(false)}
                isLoading={loadingForgotPassword}
                disabled={loadingForgotPassword}
              />
              <Button
                title="Enviar E-mail"
                buttonColor="#2ec9b7"
                containerStyles={{ flexGrow: 1 }}
                onClick={() => sendForgotPassword()}
                isLoading={loadingForgotPassword}
                disabled={loadingForgotPassword}
              />
            </div>
          </>
        ) : (
          <>
            <ModalBodyStrap>
              <p style={{ margin: 0 }}>
                Você não participa desse clube, faça login para participar
              </p>
              <PhoneDisplay>{userPhone}</PhoneDisplay>
              <DefaultInput
                title="Senha"
                type="password"
                placeholder="Insira sua senha"
                value={clubLogin.password}
                onChange={e =>
                  setClubLogin({ ...clubLogin, password: e.target.value })
                }
              />
            </ModalBodyStrap>
            <ModalFooterStrap>
              <Button
                title="Login"
                buttonColor="#2ec9b7"
                onClick={() => loginAndRelate()}
                isLoading={clubRelateLoading}
                disabled={clubRelateLoading}
                style={{ height: 48 }}
              />
              <ForgotPass onClick={() => setForgotPasswordModal(true)}>
                Esqueci minha senha
              </ForgotPass>
            </ModalFooterStrap>
          </>
        )}
      </ModalCashback>
    </Container>
  );
};

export default Menu;
