import React from 'react';
import {
  FormCardBody,
  FormCardContainer,
  FormCardFooter,
  FormCardHeader,
  FormCardInfo,
  FormCardMain,
} from './styles';

const FormCard = ({ children }) => {
  return (
    <FormCardContainer>
      <FormCardMain>{children}</FormCardMain>
    </FormCardContainer>
  );
};

FormCard.Header = ({ children }) => {
  return <FormCardHeader>{children}</FormCardHeader>;
};

FormCard.Body = ({ children }) => {
  return <FormCardBody>{children}</FormCardBody>;
};

FormCard.Info = ({ children }) => {
  return <FormCardInfo>{children}</FormCardInfo>;
};

FormCard.Buttons = ({ children, vertical }) => {
  return <FormCardFooter vertical={vertical}>{children}</FormCardFooter>;
};

export default FormCard;
