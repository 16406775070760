import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Modal from 'react-modal';
import ReactPixel from 'react-facebook-pixel';
import { uuid } from 'uuidv4';
import { useAuth } from '../../../context/auth';
import { useCart } from '../../../context/cart';
import api from '../../../services/api';

import {
  Container,
  Content,
  Message,
  PaymentLink,
  Button,
  ConfirmPixButton,
  PixArea,
  ModalBody,
  ModalFooter,
  ModalTitle,
  ConfirmButton,
  HelpPixButton,
} from './styles';

import logoPicpay from '../../../assets/images/picpay-logo_BR.png';

import faltaPouco from '../../../assets/images/faltaPouco.svg';
import pix from '../../../assets/images/pix.png';
import logopix from '../../../assets/images/logopix.png';
import { useBaskets } from '../../../context/BasketsContext';
import { useOrder } from '../../../context/order';

const AlmostThere = () => {
  const {
    picpayReferenceId,
    userPaymentMethod,
    isSmsServiceOptional,
    userChosePaySms,
    restaurantHasSmsService,
    userDeliveryType,
    pixCodePayment,
    zoopPixCodePayment,
    restaurantPixelId2,
    restaurantMetaToken2,
    subscriptionIdPixel2,
  } = useAuth();

  const { fetchData } = useBaskets();
  const [buttonCopyText, setButtonCopyText] = useState('Copiar Código Pix');
  const [modalVerifyPix, setModalVerifyPix] = useState(false);
  const { ordersTotalValue } = useOrder();
  const { closeCart, closeCartPixel, cartPixel } = useCart();

  const [modalIsOpen, setIsOpen] = useState(
    userPaymentMethod.name.toString() === 'pix_online',
  );

  const history = useHistory();

  function closeModal() {
    setIsOpen(false);
  }

  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '7px',
    },
  };

  function toggleModalVerifyPix() {
    setModalVerifyPix(!modalVerifyPix);
  }


  // pixel_fb
  const aleatorID = uuid();
  const handleSendDataToPixel = useCallback(
    (items, ids) => {
      ReactPixel.fbq(
        'track',
        'Purchase',
        {
          content_ids: ids,
          currency: 'BRL',
          contents: items,
          value: ordersTotalValue,
        },
        {
          eventID: aleatorID,
        },
      );

      closeCartPixel();
    },
    [ordersTotalValue, closeCartPixel],
  );

  const handleSendDataToApiConversion = useCallback(
    (items, ids) => {
      api.post(
        `https://graph.facebook.com/v19.0/${restaurantPixelId2}/events?=${restaurantMetaToken2}`,
        {
          pixel_id: restaurantPixelId2,
          access_token: restaurantMetaToken2,
          data: [
            {
              event_name: 'Purchase',
              event_time: Math.floor(Date.now() / 1000),
              action_source: 'website',
              event_id: aleatorID,
              user_data: {
                client_user_agent: navigator.userAgent,
                subscription_id: subscriptionIdPixel2,
              },
              custom_data: {
                content_ids: ids,
                currency: 'BRL',
                contents: items,
                value: ordersTotalValue,
              },
            },
          ],
          partner_agent: 'Takeat',
        },
      );

      closeCartPixel();
    },
    [
      ordersTotalValue,
      closeCartPixel,
      restaurantPixelId2,
      restaurantMetaToken2,
      subscriptionIdPixel2,
    ],
  );

  useEffect(() => {
    const items = cartPixel.map(item => {
      return JSON.stringify({
        id: `${item.name}`,
        quantity: `${item.amount}`,
      });
    });
    const ids = cartPixel.map(item => item.id);

    if (restaurantPixelId2) {
      handleSendDataToPixel(items, ids);
    }

    if (restaurantPixelId2 && restaurantMetaToken2) {
      handleSendDataToApiConversion(items, ids);
    }
  }, [
    restaurantPixelId2,
    handleSendDataToPixel,
    handleSendDataToApiConversion,
    restaurantMetaToken2,
  ]);

  async function handleNavigate() {
    if (userPaymentMethod.name.toString() === 'pix_online') {
      try {
        const response = await api.post('client/verify-pix', {
          zoop_id: zoopPixCodePayment,
        });

        if (response.data.paid) {
          closeCart();
          history.push(`/app/delivery-orders`);
        } else {
          toggleModalVerifyPix();
        }
      } catch (e) {
        alert('Erro rota validar pix');
      }
    } else {
      closeCart();
      fetchData();
      history.push(`/app/delivery-orders`);
    }
  }

  // console.log('userPaymentMethod name', userPaymentMethod.name.toString());
  // console.log('userPaymentMethod type', userPaymentMethod.type.toString());

  // console.log('userDeliveryType', userDeliveryType.toString());

  // console.log('restaurantHasSmsService', restaurantHasSmsService.toString());
  // console.log('isSmsServiceOptional', isSmsServiceOptional.toString());
  // console.log('userChosePaySms', userChosePaySms.toString());

  return (
    <Container>
      <Content>
        <Message>
          {userPaymentMethod.name.toString() !== 'pix_online' ? (
            <>
              <img
                src={faltaPouco}
                alt="Ilustração com pessoa segurando o celular"
              />
              <h2>
                {userPaymentMethod.name.toString() === 'credito'
                  ? 'Pagamento confirmado!'
                  : 'Agora falta pouco'}
              </h2>
            </>
          ) : (
            <>
              <img src={logopix} alt="Pix" style={{ width: 150 }} />
              <h3>Finalize o pagamento via Pix!</h3>
            </>
          )}
          {(() => {
            if (restaurantHasSmsService.toString() === 'true') {
              if (
                (isSmsServiceOptional.toString() === 'true' &&
                  userChosePaySms.toString() === 'true') ||
                (restaurantHasSmsService.toString() === 'true' &&
                  isSmsServiceOptional.toString() === 'false')
              ) {
                return (
                  <>
                    {userPaymentMethod.name.toString() === 'picpay' &&
                      (userDeliveryType.toString() === 'withdrawal' ||
                        !userDeliveryType) && (
                        <p>
                          Assim que o pagamento for realizado e confirmado, o
                          seu pedido irá para preparo. Caso ainda não o tenha
                          efetuado, basta clicar no botão PicPay abaixo.
                          Acompanhe o status na opção Meus Pedidos. Você
                          receberá um sms assim que seu pedido ficar pronto para
                          retirada.
                        </p>
                      )}

                    {userPaymentMethod.name.toString() === 'pix_online' &&
                      (userDeliveryType.toString() === 'withdrawal' ||
                        !userDeliveryType) && (
                        <PixArea>
                          <p>Código Pix Copia e Cola</p>
                          <input value={pixCodePayment} />
                          <ConfirmPixButton
                            onClick={() => {
                              navigator.clipboard.writeText(pixCodePayment);
                              setButtonCopyText('Código Copiado!');
                            }}
                          >
                            {buttonCopyText}
                          </ConfirmPixButton>
                          <HelpPixButton
                            style={{ marginTop: 5 }}
                            onClick={() => {
                              setIsOpen(true);
                            }}
                          >
                            Como pagar com Pix
                          </HelpPixButton>
                          {/* <p>Copie o código PIX acima, abra o app do seu banco preferido, vá na área PIX e cole o código PIX para efetuar o pagamento!</p> */}
                          <p>
                            <strong>O Código expira em 5 minutos!</strong>
                          </p>
                        </PixArea>
                      )}

                    {userPaymentMethod.type.toString() === 'online' &&
                      userPaymentMethod.name.toString() === 'credito' &&
                      (userDeliveryType.toString() === 'withdrawal' ||
                        !userDeliveryType) && (
                        <p>
                          Seu pedido já foi enviado para o restaurante,
                          acompanhe o status na página
                          <strong> Meus Pedidos</strong>
                          . Você receberá um sms
                          assim que seu pedido ficar pronto para retirada.
                        </p>
                      )}

                    {userPaymentMethod.type.toString() === 'online' &&
                      userPaymentMethod.name.toString() === 'credito' &&
                      userDeliveryType.toString() === 'delivery' && (
                        <p>
                          Seu pedido já foi enviado para o restaurante,
                          acompanhe o status na página
                          <strong> Meus Pedidos</strong>
                          .
                        </p>
                      )}

                    {userPaymentMethod.type.toString() === 'online' &&
                      userPaymentMethod.name.toString() === 'picpay' &&
                      userDeliveryType.toString() === 'delivery' && (
                        <p>
                          Assim que o pagamento for realizado e confirmado, o
                          seu pedido irá para preparo. Caso ainda não o tenha
                          efetuado, basta clicar no botão PicPay abaixo.
                          Acompanhe o status na opção Meus Pedidos. Você
                          receberá um sms assim que seu pedido sair para
                          entrega.
                        </p>
                      )}

                    {userPaymentMethod.type.toString() === 'online' &&
                      userPaymentMethod.name.toString() === 'pix_online' &&
                      userDeliveryType.toString() === 'delivery' && (
                        <PixArea>
                          <p>Código Pix Copia e Cola</p>
                          <input value={pixCodePayment} />
                          <ConfirmPixButton
                            onClick={() => {
                              navigator.clipboard.writeText(pixCodePayment);
                              setButtonCopyText('Código Copiado!');
                            }}
                          >
                            {buttonCopyText}
                          </ConfirmPixButton>
                          <HelpPixButton
                            style={{ marginTop: 5 }}
                            onClick={() => {
                              setIsOpen(true);
                            }}
                          >
                            Como pagar com Pix
                          </HelpPixButton>
                          {/* <p>Copie o código PIX acima, abra o app do seu banco preferido, vá na área PIX e cole o código PIX para efetuar o pagamento!</p> */}
                          <p>
                            <strong>O Código expira em 5 minutos!</strong>
                          </p>
                        </PixArea>
                      )}

                    {userPaymentMethod.type.toString() === 'withdrawal' && (
                      <p>
                        O seu pedido irá para preparo. Acompanhe o status na
                        opção
                        <strong> Meus Pedidos</strong>. Você receberá um sms
                        assim que seu pedido ficar pronto para retirada. Efetue
                        o pagamento ao retirar o pedido.
                      </p>
                    )}

                    {userPaymentMethod.type.toString() === 'delivery' && (
                      <p>
                        O seu pedido foi enviado ao restaurante. Você receberá
                        um sms assim que seu pedido sair para entrega. Acompanhe
                        o status na opção
                        <strong> Meus Pedidos</strong>. Efetue o pagamento ao
                        receber o pedido.
                      </p>
                    )}
                  </>
                );
              }
              return (
                <>
                  {userPaymentMethod.name.toString() === 'picpay' &&
                    (userDeliveryType.toString() === 'withdrawal' ||
                      !userDeliveryType) && (
                      <p>
                        Assim que o pagamento for realizado e confirmado, o seu
                        pedido irá para preparo. Caso ainda não o tenha
                        efetuado, basta clicar no botão PicPay abaixo. Acompanhe
                        o status na opção
                        {' '}
                        <strong> Meus Pedidos</strong>
                        {' '}
                        e fique
                        atento a senha do seu pedido no painel eletrônico da
                        loja.
                      </p>
                    )}
                  {userPaymentMethod.name.toString() === 'pix_online' &&
                    (userDeliveryType.toString() === 'withdrawal' ||
                      !userDeliveryType) && (
                      <PixArea>
                        <p>Código Pix Copia e Cola</p>
                        <input value={pixCodePayment} />
                        <ConfirmPixButton
                          onClick={() => {
                            navigator.clipboard.writeText(pixCodePayment);
                            setButtonCopyText('Código Copiado!');
                          }}
                        >
                          {buttonCopyText}
                        </ConfirmPixButton>
                        <HelpPixButton
                          style={{ marginTop: 5 }}
                          onClick={() => {
                            setIsOpen(true);
                          }}
                        >
                          Como pagar com Pix
                        </HelpPixButton>
                        {/* <p>Copie o código PIX acima, abra o app do seu banco preferido, vá na área PIX e cole o código PIX para efetuar o pagamento!</p> */}
                        <p>
                          <strong>O Código expira em 5 minutos!</strong>
                        </p>
                      </PixArea>
                    )}

                  {userPaymentMethod.name.toString() === 'picpay' &&
                    userDeliveryType.toString() === 'delivery' && (
                      <p>
                        Assim que o pagamento for realizado e confirmado, o seu
                        pedido irá para preparo. Caso ainda não o tenha
                        efetuado, basta clicar no botão PicPay abaixo. Acompanhe
                        o status na opção
                        {' '}
                        <strong> Meus Pedidos</strong>{' '}
                      </p>
                    )}

                  {userPaymentMethod.name.toString() === 'pix_online' &&
                    userDeliveryType.toString() === 'delivery' && (
                      <PixArea>
                        <p>Código Pix Copia e Cola</p>
                        <input value={pixCodePayment} />
                        <ConfirmPixButton
                          onClick={() => {
                            navigator.clipboard.writeText(pixCodePayment);
                            setButtonCopyText('Código Copiado!');
                          }}
                        >
                          {buttonCopyText}
                        </ConfirmPixButton>
                        <HelpPixButton
                          style={{ marginTop: 5 }}
                          onClick={() => {
                            setIsOpen(true);
                          }}
                        >
                          Como pagar com Pix
                        </HelpPixButton>
                        {/* <p>Copie o código PIX acima, abra o app do seu banco preferido, vá na área PIX e cole o código PIX para efetuar o pagamento!</p> */}
                        <p>
                          <strong>O Código expira em 5 minutos!</strong>
                        </p>
                      </PixArea>
                    )}

                  {userPaymentMethod.type.toString() === 'online' &&
                    userPaymentMethod.name.toString() === 'credito' &&
                    (userDeliveryType.toString() === 'withdrawal' ||
                      !userDeliveryType) && (
                      <p>
                        Seu pedido já foi enviado para o restaurante, acompanhe
                        o status na página
                        <strong> Meus Pedidos</strong>
                        . Você receberá um sms
                        assim que seu pedido ficar pronto para retirada.
                      </p>
                    )}

                  {userPaymentMethod.type.toString() === 'online' &&
                    userPaymentMethod.name.toString() === 'credito' &&
                    userDeliveryType.toString() === 'delivery' && (
                      <p>
                        Seu pedido já foi enviado para o restaurante, acompanhe
                        o status na página
                        <strong> Meus Pedidos</strong>
                        .
                      </p>
                    )}

                  {userPaymentMethod.type.toString() === 'withdrawal' && (
                    <p>
                      O seu pedido foi enviado ao restaurante. Acompanhe o
                      status na opção
                      <strong> Meus Pedidos</strong> e fique atento a senha do
                      seu pedido no painel eletrônico da loja. Efetue o
                      pagamento ao retirar o pedido.
                    </p>
                  )}

                  {userPaymentMethod.type.toString() === 'delivery' && (
                    <p>
                      O seu pedido foi enviado ao restaurante. Acompanhe o
                      status na opção
                      <strong> Meus Pedidos</strong>. Efetue o pagamento ao
                      receber o pedido.
                    </p>
                  )}
                </>
              );
            }
            return (
              <>
                {userPaymentMethod.name.toString() === 'picpay' && (
                  <p>
                    Assim que o pagamento for realizado e confirmado, o seu
                    pedido irá para preparo. Caso ainda não o tenha efetuado,
                    basta clicar no botão PicPay abaixo. Acompanhe o status na
                    opção
                    {' '}
                    <strong>Meus Pedidos</strong> e fique atento a senha
                    do seu pedido no painel eletrônico da loja.
                  </p>
                )}

                {userPaymentMethod.name.toString() === 'pix_online' && (
                  <PixArea>
                    <p>Código Pix Copia e Cola</p>
                    <input value={pixCodePayment} />
                    <ConfirmPixButton
                      onClick={() => {
                        navigator.clipboard.writeText(pixCodePayment);
                        setButtonCopyText('Código Copiado!');
                      }}
                    >
                      {buttonCopyText}
                    </ConfirmPixButton>
                    <HelpPixButton
                      style={{ marginTop: 5 }}
                      onClick={() => {
                        setIsOpen(true);
                      }}
                    >
                      Como pagar com Pix
                    </HelpPixButton>
                    {/* <p>Copie o código PIX acima, abra o app do seu banco preferido, vá na área PIX e cole o código PIX para efetuar o pagamento!</p> */}
                    <p>
                      <strong>O Código expira em 5 minutos!</strong>
                    </p>
                  </PixArea>
                )}
                {userPaymentMethod.type.toString() === 'online' &&
                  userPaymentMethod.name.toString() === 'credito' && (
                    <p>
                      Seu pedido já foi enviado para o restaurante, acompanhe o
                      status na página
                      <strong> Meus Pedidos</strong>
                      .
                    </p>
                  )}

                {/* {userPaymentMethod.type.toString() === 'online' &&
                  userPaymentMethod.name.toString() === 'credito' &&
                  userDeliveryType.toString() === 'delivery' && (
                    <p>
                      Seu pedido já foi enviado para o restaurante, acompanhe o status na página
                        <strong> Meus Pedidos</strong>.
                    </p>
                  )} */}

                {userPaymentMethod.type.toString() === 'withdrawal' && (
                  <p>
                    O seu pedido foi enviado ao restaurante. Acompanhe o status
                    na opção <strong>Meus Pedidos</strong>
                    {' '}
                    e fique atento a
                    senha do seu pedido no painel eletrônico da loja. Efetue o
                    pagamento ao retirar o pedido.
                  </p>
                )}

                {userPaymentMethod.type.toString() === 'delivery' && (
                  <p>
                    O seu pedido irá para preparo. Acompanhe o status na opção
                    <strong> Meus Pedidos</strong>
                    . Efetue o pagamento ao
                    receber o pedido.
                  </p>
                )}
              </>
            );
          })()}

          {userPaymentMethod.name.toString() === 'picpay' && (
            <PaymentLink
              href={`picpay://picpay/checkout/${picpayReferenceId}`}
              target="_blank"
            >
              <img src={logoPicpay} alt="logo PicPay" />
              <p>Ir para o Picpay!</p>
            </PaymentLink>
          )}
        </Message>

        {userPaymentMethod.type.toString() === 'withdrawal' ||
          userPaymentMethod.type.toString() === 'delivery' ||
          userPaymentMethod.name.toString() === 'credito' ? (
          <Button onClick={handleNavigate}>Ok, entendi !</Button>
        ) : (
          <Button onClick={handleNavigate}>Já realizei o pagamento</Button>
        )}
      </Content>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Descrição do Item"
      >
        <ModalTitle>
          <p>Como pagar pedidos com Pix</p>
        </ModalTitle>
        <ModalBody>
          <div>
            <img src={pix} alt="logopix" />
          </div>
          <p>
            <strong>1º Passo</strong>
          </p>
          <p>Copie o código que foi gerado</p>
          <p style={{ marginTop: 13 }}>
            <strong>2º Passo</strong>
          </p>
          <p>
            Abra um aplicativo em que você tenha o Pix habilitado e use a opção
            Pix Copia e Cola
          </p>
          <p style={{ marginTop: 13 }}>
            <strong>3º Passo</strong>
          </p>
          <p>
            Cole o código, confirme o valor e faça o pagamento. Ele será
            confirmado em no máximo 2 minutos!
          </p>
        </ModalBody>
        <ModalFooter>
          <ConfirmButton onClick={closeModal}>Ok, entendi!</ConfirmButton>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalVerifyPix}
        onRequestClose={toggleModalVerifyPix}
        style={customStyles}
        contentLabel="Descrição do Item"
      >
        <ModalTitle>
          <p>Pagamento sendo processado...</p>
        </ModalTitle>

        <ModalFooter>
          <ConfirmButton onClick={toggleModalVerifyPix}>
            Ok, entendi!
          </ConfirmButton>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default AlmostThere;
