import React, { useState, useEffect, useCallback, useRef } from 'react';

import { useHistory } from 'react-router-dom';
// import Modal from 'react-modal';
import { Modal, ModalFooter } from 'reactstrap';
import ReactPixel from 'react-facebook-pixel';
import { FiMessageCircle, FiAlertCircle, FiX, FiSearch } from 'react-icons/fi';
import { TiPlus } from 'react-icons/ti';
import { RiArrowDropLeftLine } from 'react-icons/ri';

import { FaMinus } from 'react-icons/fa';
import { useCart } from '../../context/cart';
import { useAuth } from '../../context/auth';
import fotoPadrao from '../../assets/images/fotoPadrao.png';

import api from '../../services/api';

import {
  Container,
  Content,
  ImageProduct,
  DescriptionProduct,
  AddItemsProduct,
  EditAction,
  EditActionButtons,
  ComplementsHeaderProduct,
  ComplementItem,
  ComplementItemDescription,
  ComplementItemAmount,
  InformationText,
  ConfirmButton,
  ModalTitle,
  Footer,
  CloseButton,
  QuestionSubtitle,
  ProductName,
  InputProductAmount,
  InputProductWeight,
  InputComplementAmount,
  SearchBar,
  ButtonSearchInput,
  ButtonDeleteInput,
  BackButton,
  OrderButton,
  InputContainer,
} from './styles';
import { uuid } from 'uuidv4';
import { courtesyRestaurants } from '../../utils/constants';
import { startingPrice } from '../../utils/functions';

const Product = ({
  item: product,
  closeProductModal,
  menuTranslationsEnabled,
  menuLanguage,
}) => {
  const history = useHistory();
  const { addToCart } = useCart();

  const {
    restaurantId,
    sessionKey,
    logOut,
    restaurantIsOpen,
    restaurantDeliveryActive,
    restaurantWithdrawalActive,
    restaurantOnlyQrcode,
    orderSchedulingActive,
    setTableNumber,
    tableType,
    formatValue,
    restaurantPixelId,
    restaurantMetaToken,
    subscriptionIdPixel,
    restaurantPixelId2,
    restaurantMetaToken2,
    subscriptionIdPixel2,
  } = useAuth();

  const isDelivery = tableType === 'delivery';

  const isCourtesy = courtesyRestaurants.includes(restaurantId);

  const [observation, setObservation] = useState('');
  const [amount, setAmount] = useState(1);
  const [modalPhrase, setModalPhrase] = useState('');
  const [modalAction, setModalAction] = useState([]);
  const [complementsCategory, setComplementsCategory] = useState([]);
  const [mandatoryComplements, setMandatoryComplements] = useState([]);
  const [isProductWeight, setIsProductWeight] = useState(product.use_weight);
  const [inputProductWeight, setInputProductWeight] = useState(0);

  const [modalIsOpen, setIsOpen] = useState(false);

  const [filter, setFilter] = useState([]);
  const [complementsFiltered, setomplementsFiltered] = useState([]);

  function addOrUpdateFilter(key, filterName) {
    const newFilter = { id: key, nome: filterName };
    const existingFilter = complementsFiltered.find(
      filtro => filtro.id === key,
    );

    if (existingFilter) {
      const updatedFilters = complementsFiltered.map(filtro =>
        filtro.id === key ? newFilter : filtro,
      );
      setomplementsFiltered(updatedFilters);
    } else {
      const updatedFilters = [...complementsFiltered, newFilter];
      setomplementsFiltered(updatedFilters);
    }
  }

  function deleteValue(complement) {
    const inputElement = document.getElementById(complement?.name);
    inputElement.value = '';
    addOrUpdateFilter(complement.id, '');
  }

  const inputComplementRef = useRef(null);

  const maskOnlyNumbers = value => {
    return Number(value.replace(/\D/g, ''));
  };

  function toggleModal() {
    setIsOpen(!modalIsOpen);
  }

  function closeModal() {
    setIsOpen(false);

    if (modalAction.length > 0) {
      modalAction[0]();
    }
  }

  function openModal(phrase) {
    setIsOpen(true);
    setModalPhrase(phrase);
  }

  useEffect(() => {
    const mandatoryDelivery = product.complement_categories
      .filter(item => item.available_in_delivery)
      .filter(
        item =>
          item.complements.filter(compl => compl.available_in_delivery).length >
          0 && item.optional.toString() === 'false',
      )
      .map(cc => {
        return {
          ...cc,
          complements: cc.complements.map(complement => {
            return {
              ...complement,
              checkAmount: 0,
            };
          }),
        };
      });

    const mandatoryPDV = product.complement_categories
      .filter(item => item.available)
      .filter(
        item =>
          item.complements.filter(compl => compl.available).length > 0 &&
          item.optional.toString() === 'false',
      )
      .map(cc => {
        return {
          ...cc,
          complements: cc.complements.map(complement => {
            return {
              ...complement,
              checkAmount: 0,
            };
          }),
        };
      });

    if (tableType === 'delivery') {
      setMandatoryComplements(mandatoryDelivery);
    } else {
      setMandatoryComplements(mandatoryPDV);
    }
  }, [product.complement_categories, tableType]);

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await api.get('client/bills', {
          params: { session_key: sessionKey },
        });

        setTableNumber(res.data.session.table.table_number);
      } catch (err) {
        const { error } = err.response.data;

        if (err.response.status === 400) {
          console.log(error);
          openModal('Sua conta já foi fechada');
          setModalAction([
            () => {
              logOut();
              history.push('/');
            },
          ]);
        }
      }
    }
    if (sessionKey && tableType !== 'delivery' && tableType !== 'prepaid') {
      fetchData();
    }
  }, [sessionKey, history, logOut]);

  function handleAddObservation(e) {
    setObservation(e.target.value);
  }

  function increment() {
    setAmount(amount + 1);
  }

  function decrement() {
    amount > 1 && setAmount(amount - 1)

  }

  function handleAddComplements(
    id,
    name,
    price,
    delivery_price,
    limit,
    categoryLimit,
    categoryId,
    categoryAdditional,
    categoryMoreExpensiveOnly,
    minimum,
    useAverage,
    cheapest,
    cheapest_delivery,
  ) {
    const priceToUse =
      tableType === 'delivery' ? delivery_price || price : price;
    // adicionar os complementos das categorias obrigatorias no state
    const categoryFind = mandatoryComplements.findIndex(
      category => category.id === categoryId,
    );
    if (categoryFind >= 0) {
      const complementFind = mandatoryComplements[
        categoryFind
      ].complements.findIndex(item => item.id === id);

      if (complementFind >= 0) {
        mandatoryComplements[categoryFind].complements[
          complementFind
        ].checkAmount += 1;
      }
    }

    // Adicionando aos complementos
    const newCategoryExist = [...complementsCategory];
    const categoryExist = newCategoryExist.findIndex(
      category => category.id === categoryId,
    );

    if (categoryExist >= 0) {
      const categoryTotalAmount = complementsCategory[
        categoryExist
      ].complements.reduce((acum, curr) => acum + curr.amount, 0);

      const complementExist = newCategoryExist[
        categoryExist
      ].complements.findIndex(complement => complement.id === id);

      if (complementExist >= 0) {
        if (categoryTotalAmount < categoryLimit) {
          if (
            newCategoryExist[categoryExist].complements[complementExist]
              .amount < limit
          ) {
            newCategoryExist[categoryExist].complements[
              complementExist
            ].amount += 1;

            newCategoryExist[categoryExist].minimum = minimum;
          } else {
            openModal(
              'Você só pode adicionar outro complemento ao seu lanche, pois o limite deste complemento foi alcançado.',
            );
          }
        } else {
          openModal('Limite de catgoria');
        }
      } else {
        newCategoryExist[categoryExist].complements.push({
          id,
          name,
          price,
          delivery_price,
          amount: 1,
        });
        newCategoryExist[categoryExist].minimum = minimum;
      }

      setComplementsCategory(newCategoryExist);
    } else {
      setComplementsCategory([
        ...complementsCategory,
        {
          id: categoryId,
          additional: categoryAdditional,
          more_expensive_only: categoryMoreExpensiveOnly,
          complements: [{ id, name, price, delivery_price, amount: 1 }],
          minimum,
          use_average: useAverage,
          cheapest,
          cheapest_delivery,
        },
      ]);
    }
  }

  const [stateFocus, setStateFocus] = useState([]);

  function handleChangeComplementAmount(
    amount,
    id,
    name,
    price,
    delivery_price,
    limit,
    categoryLimit,
    categoryId,
    categoryAdditional,
    categoryMoreExpensiveOnly,
    minimum,
    useAverage,
  ) {
    // adicionar os complementos das categorias obrigatorias no state

    setStateFocus([id]);

    const categoryFind = mandatoryComplements.findIndex(
      category => category.id === categoryId,
    );

    if (categoryFind >= 0) {
      const complementFind = mandatoryComplements[
        categoryFind
      ].complements.findIndex(item => item.id === id);

      if (complementFind >= 0) {
        const categoryTotalAmount = mandatoryComplements[
          categoryFind
        ]?.complements.reduce((acum, curr) => acum + curr.amount, 0);
        if (
          Number(amount) <= Number(categoryLimit) &&
          Number(amount) <= Number(limit)
        ) {
          if (categoryTotalAmount > 0) {
            if (
              Number(amount) <= Number(categoryLimit) &&
              Number(amount) + Number(categoryTotalAmount) <=
              Number(categoryLimit)
            ) {
              mandatoryComplements[categoryFind].complements[
                complementFind
              ].checkAmount = amount;
            }
          } else {
            mandatoryComplements[categoryFind].complements[
              complementFind
            ].checkAmount = amount;
          }
        }
      }
    }

    // Adicionando aos complementos
    const newCategoryExist = [...complementsCategory];
    const categoryExist = newCategoryExist.findIndex(
      category => category.id === categoryId,
    );

    const inputElement = document.getElementById(id.toString());

    if (categoryExist >= 0) {
      const categoryTotalAmount = complementsCategory[
        categoryExist
      ].complements.reduce((acum, curr) => acum + curr.amount, 0);

      const complementExist = newCategoryExist[
        categoryExist
      ].complements.findIndex(complement => complement.id === id);
      if (
        complementExist >= 0 &&
        newCategoryExist[categoryExist].complements.length === 1
      ) {
        if (
          Number(amount) >= 0 &&
          Number(amount) <= Number(categoryLimit)
          // Number(amount) + Number(categoryTotalAmount) <= Number(categoryLimit)
        ) {
          if (Number(amount) <= Number(limit)) {
            newCategoryExist[categoryExist].complements[
              complementExist
            ].amount = amount;
            newCategoryExist[categoryExist].minimum = minimum;
          } else {
            openModal(
              'Quantidade digitade é maior que o limite do complemento',
            );
          }
        } else {
          openModal(
            'Essa quantidade digitada é maior que o limite máximo permitido para essa categoria.',
          );
          if (inputElement) {
            inputElement.value = '';
          }
          console.log('h');
        }
      } else if (
        complementExist >= 0 &&
        newCategoryExist[categoryExist].complements.length > 1
      ) {
        if (
          Number(amount) + Number(categoryTotalAmount) >
          Number(categoryLimit)
        ) {
          openModal('Quantidade digitade é maior que o limite da categoria');
          console.log('a');
          console.log(inputElement);
          if (inputElement) {
            inputElement.value = '';
          }
        } else if (
          Number(amount) >= 0 &&
          Number(amount) <= Number(categoryLimit) &&
          Number(categoryTotalAmount) < Number(categoryLimit)
        ) {
          if (Number(amount) <= Number(limit)) {
            newCategoryExist[categoryExist].complements[
              complementExist
            ].amount = amount;
            newCategoryExist[categoryExist].minimum = minimum;
          } else {
            openModal(
              'Quantidade digitade é maior que o limite do complemento',
            );
            if (inputElement) {
              inputElement.value = '';
            }
          }
        } else {
          openModal(
            'Essa quantidade digitada é maior que o limite máximo permitido para essa categoria.',
          );
          if (inputElement) {
            inputElement.value = '';
          }
          console.log('g');
        }
      } else if (amount < 0) {
        return 0;
      } else if (
        Number(amount) <= Number(categoryLimit) &&
        Number(amount) <= Number(limit)
        // Number(amount) + Number(categoryTotalAmount) <= Number(categoryLimit)
      ) {
        if (
          Number(amount) <= Number(categoryLimit) &&
          Number(amount) <= Number(limit) &&
          Number(amount) + Number(categoryTotalAmount) <= Number(categoryLimit)
        ) {
          newCategoryExist[categoryExist].complements.push({
            id,
            name,
            price,
            delivery_price,
            amount,
          });

          newCategoryExist[categoryExist].minimum = minimum;
        } else {
          openModal(
            'Essa quantidade digitada é maior que o limite máximo permitido para essa categoria.',
          );
          console.log('f');
          if (inputElement) {
            inputElement.value = '';
          }
        }
      } else if (
        Number(amount) + Number(categoryTotalAmount) >
        Number(categoryLimit)
      ) {
        openModal('Quantidade digitade é maior que o limite da categoria');
        console.log('b');
        if (inputElement) {
          inputElement.value = '';
        }
      } else {
        openModal(
          'Essa quantidade digitada é maior que o limite máximo permitido para esse complemento.',
        );
        if (inputElement) {
          inputElement.value = '';
        }
      }

      setComplementsCategory(newCategoryExist);
    } else if (amount < 0) {
      return 0;
    } else if (amount <= categoryLimit && amount <= limit) {
      setComplementsCategory([
        ...complementsCategory,
        {
          id: categoryId,
          additional: categoryAdditional,
          complements: [
            {
              id,
              name,
              price,
              delivery_price,
              amount,
            },
          ],
          more_expensive_only: categoryMoreExpensiveOnly,
          minimum,
          use_average: useAverage,
        },
      ]);
    } else if (Number(amount) > Number(categoryLimit)) {
      openModal('Quantidade digitade é maior que o limite da categoria');
      console.log('c');
      console.log(inputElement);
      if (inputElement) {
        inputElement.value = '';
      }
    } else {
      openModal(
        'Essa quantidade digitada é maior que o limite máximo permitido para esse complemento.',
      );
      if (inputElement) {
        inputElement.value = '';
      }
    }
  }

  function handleRemoveComplements(categoryId, complementId) {
    // remover os complementos das categorias obrigatorias no state
    const categoryFind = mandatoryComplements.findIndex(
      category => category.id === categoryId,
    );
    if (categoryFind >= 0) {
      const complementFind = mandatoryComplements[
        categoryFind
      ].complements.findIndex(item => item.id === complementId);

      if (complementFind !== -1) {
        if (
          mandatoryComplements[categoryFind].complements[complementFind]
            .checkAmount > 0
        ) {
          mandatoryComplements[categoryFind].complements[
            complementFind
          ].checkAmount -= 1;
        }
      }
    }

    // Remover complemento das categorias
    const newCategoryExist = [...complementsCategory];
    const categoryExistId = newCategoryExist.findIndex(
      category => category.id === categoryId,
    );

    if (categoryExistId >= 0) {
      const complementExist = newCategoryExist[
        categoryExistId
      ].complements.findIndex(complement => complement.id === complementId);

      if (
        newCategoryExist[categoryExistId].complements[complementExist].amount >
        0
      ) {
        newCategoryExist[categoryExistId].complements[
          complementExist
        ].amount -= 1;

        setComplementsCategory(newCategoryExist);
      }
    }
  }

  let complementMoreExpensive = 0;
  complementsCategory
    .filter(category => category.more_expensive_only === true)
    .forEach(item => {
      if (tableType === 'delivery') {
        let local_price = 0;
        item.complements.forEach(complement => {
          if (
            local_price <
            parseFloat(
              complement.delivery_price
                ? complement.delivery_price
                : complement.price,
            ) *
            (parseInt(complement.amount) >= 1 ? 1 : 0)
          ) {
            local_price =
              parseFloat(
                complement.delivery_price
                  ? complement.delivery_price
                  : complement.price,
              ) * (parseInt(complement.amount) >= 1 ? 1 : 0);
          }
        });
        complementMoreExpensive += local_price;
      } else {
        let local_price = 0;
        item.complements.forEach(complement => {
          if (
            local_price <
            parseFloat(complement.price) *
            (parseInt(complement.amount) >= 1 ? 1 : 0)
          ) {
            local_price =
              parseFloat(complement.price) *
              (parseInt(complement.amount) >= 1 ? 1 : 0);
          }
        });
        complementMoreExpensive += local_price;
      }
    });

  const totalComplementsPrice =
    tableType === 'delivery'
      ? complementsCategory
        .filter(
          category =>
            category.additional === true &&
            category.more_expensive_only === false,
        )
        .map(complementCat => {
          if (complementCat.use_average) {
            const amountAverage = complementCat.complements.reduce(
              (accum, curr) => accum + curr.amount,
              0,
            );

            const totalPriceAverage =
              complementCat.complements
                .map(item => item)
                .reduce(
                  (acum, curr) =>
                    acum + (curr.delivery_price || curr.price) * curr.amount,
                  0,
                ) / amountAverage;

            return totalPriceAverage > 0 ? Number(totalPriceAverage) : 0;
          }
          return complementCat.complements
            .map(item => item)
            .reduce(
              (acum, curr) =>
                acum + (curr.delivery_price || curr.price) * curr.amount,
              0,
            );
        })
        .reduce((acum, curr) => acum + curr, 0) + complementMoreExpensive
      : complementsCategory
        .filter(
          category =>
            category.additional === true &&
            category.more_expensive_only === false,
        )

        .map(complementCat => {
          if (complementCat.use_average) {
            const amountAverage = complementCat.complements.reduce(
              (accum, curr) => accum + curr.amount,
              0,
            );

            const totalPriceAverage =
              complementCat.complements
                .map(item => item)
                .reduce((acum, curr) => acum + curr.price * curr.amount, 0) /
              amountAverage;

            return totalPriceAverage > 0 ? Number(totalPriceAverage) : 0;
          }
          return complementCat.complements
            .map(item => item)
            .reduce((acum, curr) => acum + curr.price * curr.amount, 0);
        })
        .reduce((acum, curr) => acum + curr, 0) + complementMoreExpensive;

  function totalCategoryComplementsAmount(categoryId) {
    const total = complementsCategory
      .filter(category => category.id === categoryId)
      .map(item =>
        item.complements.reduce((acum, curr) => acum + curr.amount, 0),
      );

    return total;
  }

  const check = [];

  for (const cat of mandatoryComplements) {
    const category = cat.complements.reduce(
      (acum, curr) => acum + curr.checkAmount,
      0,
    );

    check.push(category);
  }

  function deleteCheckAmount() {
    mandatoryComplements.map(item =>
      item.complements.map(compl => (compl.checkAmount = 0)),
    );
  }

  //pixel_fb add to cart event
 const handleSendDataToPixelAddToCart = useCallback(() => {
    const aleatorID = uuid();
    // const items = cartPixel.map(item => {
    //   return JSON.stringify({
    //     id: `${item.name}`,
    //     quantity: `${item.amount}`,
    //   });
    // });

    // const ids = cartPixel.map(item => item.name);

    if (restaurantPixelId && tableType !== 'delivery') {
      ReactPixel.fbq(
        'track',
        'AddToCart',
        {
          content_ids: [product.name],
          content_type: 'produto',
          contents: [
            {
              id: `${product.name}`,
              quantity: isProductWeight ? inputProductWeight : amount,
            },
          ],
          currency: 'BRL',
          value: product.price,
        },
        {
          eventID: aleatorID,
        },
      );
    }

    if (restaurantPixelId2 && tableType === 'delivery') {
      ReactPixel.fbq(
        'track',
        'AddToCart',
        {
          content_ids: [product.name],
          content_type: 'produto',
          contents: [
            {
              id: `${product.name}`,
              quantity: isProductWeight ? inputProductWeight : amount,
            },
          ],
          currency: 'BRL',
          value: product.price,
        },
        {
          eventID: aleatorID,
        },
      );
    }

    if (restaurantPixelId && restaurantMetaToken && tableType !== 'delivery') {
      api.post(
        `https://graph.facebook.com/v19.0/${restaurantPixelId}/events?=${restaurantMetaToken}`,
        {
          pixel_id: restaurantPixelId,
          access_token: restaurantMetaToken,
          data: [
            {
              event_name: 'AddToCart',
              event_time: Math.floor(Date.now() / 1000),
              action_source: 'website',
              event_id: aleatorID,
              user_data: {
                client_user_agent: navigator.userAgent,
                subscription_id: subscriptionIdPixel,
              },
              custom_data: {
                content_ids: [product.name],
                content_type: 'produto',
                contents: [
                  {
                    id: `${product.name}`,
                    quantity: isProductWeight ? inputProductWeight : amount,
                  },
                ],
                currency: 'BRL',
                value: product.price,
              },
            },
          ],
          partner_agent: 'Takeat',
        },
      );
    }

    if (
      restaurantPixelId2 &&
      restaurantMetaToken2 &&
      tableType === 'delivery'
    ) {
      api.post(
        `https://graph.facebook.com/v19.0/${restaurantPixelId2}/events?=${restaurantMetaToken2}`,
        {
          pixel_id: restaurantPixelId2,
          access_token: restaurantMetaToken2,
          data: [
            {
              event_name: 'AddToCart',
              event_time: Math.floor(Date.now() / 1000),
              action_source: 'website',
              event_id: aleatorID,
              user_data: {
                client_user_agent: navigator.userAgent,
                subscription_id: subscriptionIdPixel2,
              },
              custom_data: {
                content_ids: [product.name],
                content_type: 'produto',
                contents: [
                  {
                    id: `${product.name}`,
                    quantity: isProductWeight ? inputProductWeight : amount,
                  },
                ],
                currency: 'BRL',
                value: product.price,
              },
            },
          ],
          partner_agent: 'Takeat',
        },
      );
    }
  }, [
    restaurantMetaToken,
    restaurantPixelId,
    subscriptionIdPixel,
    restaurantMetaToken2,
    restaurantPixelId2,
    subscriptionIdPixel2,
    amount,
    inputProductWeight,
  ]);
 

  function addToCarts() {
    if (isProductWeight) {
      if (inputProductWeight) {
        if (mandatoryComplements.length > 0) {
          const checkedMandatoryComplements = check.reduce(
            (acum, curr) => acum && curr > 0,
          );

          const hasMinimum = complementsCategory.filter(
            item => item.minimum > 0,
          );

          const minimumCheck = complementsCategory.map(category => {
            const complementsAmountSum = category.complements.reduce(
              (acum, curr) => curr.amount + acum,
              0,
            );

            return category.minimum > 1
              ? category.minimum <= complementsAmountSum
              : true;
          });

          if (hasMinimum) {
            if (minimumCheck.every(item => item === true)) {
              if (checkedMandatoryComplements) {
                handleSendDataToPixelAddToCart(product, amount)
                addToCart({
                  product,
                  amount,
                  observation,
                  complementsCategory,
                  isProductWeight,
                  inputProductWeight,
                });

                // history.push(`/menu/${restaurantId}`);
                handleSendDataToPixelAddToCart();
                closeProductModal();
                deleteCheckAmount();
              } else {
                openModal('Favor adicionar todos os complementos obrigatórios');
                console.log(check);
              }
            } else {
              openModal('Favor adicionar a quantidade mínima de complementos');
            }
          } else if (checkedMandatoryComplements) {
            handleSendDataToPixelAddToCart(product, amount)
            addToCart({
              product,
              amount,
              observation,
              complementsCategory,
              isProductWeight,
              inputProductWeight,
            });

            // history.push(`/menu/${restaurantId}`);
            closeProductModal();
            deleteCheckAmount();
            handleSendDataToPixelAddToCart();
          } else {
            openModal('Favor adicionar todos os complementos obrigatórios');
            console.log(check);
          }
        } else {
          const hasMinimum = complementsCategory.filter(
            item => item.minimum > 1,
          );

          const minimumCheck = complementsCategory.map(category => {
            const complementsAmountSum = category.complements.reduce(
              (acum, curr) => curr.amount + acum,
              0,
            );

            return category.minimum > 1
              ? category.minimum <= complementsAmountSum
              : true;
          });

          if (hasMinimum) {
            if (minimumCheck.every(item => item === true)) {
              handleSendDataToPixelAddToCart(product, amount)
              addToCart({
                product,
                amount,
                observation,
                complementsCategory,
                isProductWeight,
                inputProductWeight,
              });

              // history.push(`/menu/${restaurantId}`);
              closeProductModal();
              deleteCheckAmount();
              handleSendDataToPixelAddToCart();
            } else {
              openModal('Favor adicionar a quantidade mínima de complementos');
            }
          } else {
            handleSendDataToPixelAddToCart(product, amount)
            addToCart({
              product,
              amount,
              observation,
              complementsCategory,
              isProductWeight,
              inputProductWeight,
            });

            // history.push(`/menu/${restaurantId}`);
            handleSendDataToPixelAddToCart();
            closeProductModal();
            deleteCheckAmount();
          }
        }
      } else {
        openModal('Favor informar o peso do produto.');
      }
    } else if (mandatoryComplements.length > 0) {
      const checkedMandatoryComplements = check.reduce(
        (acum, curr) => acum && curr > 0,
      );

      const hasMinimum = complementsCategory.filter(item => item.minimum > 0);

      const minimumCheck = complementsCategory.map(category => {
        const complementsAmountSum = category.complements.reduce(
          (acum, curr) => curr.amount + acum,
          0,
        );

        return category.minimum > 1
          ? category.minimum <= complementsAmountSum
          : true;
      });

      if (hasMinimum) {
        if (minimumCheck.every(item => item === true)) {
          if (checkedMandatoryComplements) {
            handleSendDataToPixelAddToCart(product, amount)
            addToCart({
              product,
              amount,
              observation,
              complementsCategory,
            });

            // history.push(`/menu/${restaurantId}`);
            handleSendDataToPixelAddToCart();
            deleteCheckAmount();
            closeProductModal();
          } else {
            openModal('Favor adicionar todos os complementos obrigatórios');
            console.log(check);
          }
        } else {
          openModal('Favor adicionar a quantidade mínima de complementos');
        }
      } else if (checkedMandatoryComplements) {
        handleSendDataToPixelAddToCart(product, amount)
        addToCart({
          product,
          amount,
          observation,
          complementsCategory,
        });
        handleSendDataToPixelAddToCart();
        deleteCheckAmount();
      } else {
        openModal('Favor adicionar todos os complementos obrigatórios');
        console.log(check);
      }
    } else {
      const hasMinimum = complementsCategory.filter(item => item.minimum > 1);

      const minimumCheck = complementsCategory.map(category => {
        const complementsAmountSum = category.complements.reduce(
          (acum, curr) => curr.amount + acum,
          0,
        );

        return category.minimum > 1
          ? category.minimum <= complementsAmountSum
          : true;
      });

      if (hasMinimum) {
        if (minimumCheck.every(item => item === true)) {
          handleSendDataToPixelAddToCart(product, amount)
          addToCart({
            product,
            amount,
            observation,
            complementsCategory,
          });

          // history.push(`/menu/${restaurantId}`);
          handleSendDataToPixelAddToCart();
          deleteCheckAmount();
          closeProductModal();
        } else {
          openModal('Favor adicionar a quantidade mínima de complementos');
        }
      } else {
        handleSendDataToPixelAddToCart(product, amount)
        addToCart({
          product,
          amount,
          observation,
          complementsCategory,
        });

        // history.push(`/menu/${restaurantId}`);
        handleSendDataToPixelAddToCart();
        closeProductModal();
        deleteCheckAmount();
      }
    }
  }

  const handleCloseProductModal = () => {
    deleteCheckAmount();
    closeProductModal();
  };

  function handleChangeProductWeight(e) {
    setInputProductWeight(e.target.value);
  }

  function handleChangeProductAmount(e) {
    setAmount(Number(e));
  }

  // function handleSendDataToPixel() {
  //   const aleatorID = uuid()

  //   ReactPixel.fbq("track", "ViewContent", {
  //     content_name: product.name,
  //     // content_category: category.name,
  //     content_ids: [product.name],
  //     content_type: 'produto',
  //   }, {
  //     eventID: aleatorID
  //   })

  //   // ReactPixel.track(`ViewContent`, {
  //   //   content_name: product.name,
  //   //   // content_category: category.name,
  //   //   content_ids: [product.name],
  //   //   content_type: 'produto',
  //   // }, {}, {
  //   //   eventID: `${aleatorID}`,
  //   // });

  // }

  // useEffect(() => {
  //   handleSendDataToPixel()
  // }, [product])

  return tableType === 'delivery' ? (
    <Container>
      <Content>
        <Modal
          isOpen={modalIsOpen}
          toggle={toggleModal}
          style={{ padding: 10 }}
          onClosed={closeModal}
        >
          <ModalTitle>
            <p>{modalPhrase}</p>
          </ModalTitle>

          <ModalFooter>
            <ConfirmButton onClick={closeModal}>Ok, entendi</ConfirmButton>
          </ModalFooter>
        </Modal>

        <CloseButton type="button" onClick={handleCloseProductModal}>
          <FiX size={20} color="red" />
        </CloseButton>

        {product.image && (
          <ImageProduct>
            <img
              src={product.image.url_thumb || product.image.url}
              alt={product.description}
            />
          </ImageProduct>
        )}

        <DescriptionProduct>
          {!product.image ? (
            <ProductName>
              {menuTranslationsEnabled && menuLanguage === 'english'
                ? product?.translations?.en?.name
                  ? product?.translations?.en?.name
                  : product.name
                : product.name}
            </ProductName>
          ) : (
            <h1>
              {menuTranslationsEnabled && menuLanguage === 'english'
                ? product?.translations?.en?.name
                  ? product?.translations?.en?.name
                  : product.name
                : product.name}
            </h1>
          )}

          <span style={{ whiteSpace: 'pre-line' }}>
            {menuTranslationsEnabled && menuLanguage === 'english'
              ? product?.translations?.en?.description
                ? product?.translations?.en?.description
                : product.description
              : product.description}
          </span>
        </DescriptionProduct>

        <AddItemsProduct>
          <div>
            <EditAction>
              {isProductWeight.toString() === 'true' ? (
                <span>Peso do Produto (kg)</span>
              ) : (
                <span>Quantidade</span>
              )}
              <EditActionButtons>
                {isProductWeight ? (
                  <InputProductWeight
                    type="number"
                    placeholder="ex: 0,800"
                    onChange={e => {
                      handleChangeProductWeight(e);
                    }}
                  />
                ) : (
                  <>
                    {!product?.is_unitary ? (
                      <InputContainer>
                        <button onClick={decrement} type="button">
                          {amount > 1 && !product?.is_unitary && (
                            <FaMinus size={20} color="#FF281E" />
                          )}
                        </button>
                        <InputProductAmount
                          value={amount}
                          onChange={e =>
                            handleChangeProductAmount(
                              maskOnlyNumbers(e.target.value),
                            )
                          }
                        />

                        {!product?.is_unitary && (
                          <button onClick={increment} type="button">
                            <TiPlus size={20} color="#FF281E" />
                          </button>
                        )}
                      </InputContainer>
                    ) : (
                      <span style={{ color: '#FF281E', fontSize: 20 }}>1</span>
                    )}
                  </>
                )}
              </EditActionButtons>
            </EditAction>

            {product.complement_categories
              .filter(item => item.available_in_delivery)
              .filter(
                item =>
                  item.complements.filter(compl => compl.available_in_delivery)
                    .length > 0,
              )
              .map(complement => (
                <React.Fragment key={complement.id}>
                  <ComplementsHeaderProduct>
                    <div>
                      <div>
                        {menuTranslationsEnabled && menuLanguage === 'english'
                          ? complement?.translations?.en?.question
                            ? complement?.translations?.en?.question
                            : complement.question
                          : complement.question}
                      </div>
                      {complement.complements.length > 10 && (
                        <SearchBar>
                          <input
                            name="product"
                            type="text"
                            placeholder="Buscar Complemento"
                            style={{ height: 30 }}
                            onChange={e =>
                              addOrUpdateFilter(complement.id, e.target.value)
                            }
                            id={complement?.name}
                            ref={inputComplementRef}
                          />

                          <ButtonSearchInput>
                            <FiSearch size={14} />
                          </ButtonSearchInput>
                          <ButtonDeleteInput
                            type="button"
                            title="Mostrar todos"
                            onClick={() => {
                              deleteValue(complement);
                            }}
                          >
                            <FiX size={14} />
                          </ButtonDeleteInput>
                        </SearchBar>
                      )}
                      {complement.limit > complement.minimum && (
                        <>
                          {complement.minimum > 1 ? (
                            <>
                              <QuestionSubtitle>
                                {`Escolha entre ${complement.minimum} e ${complement.limit
                                  }
                        ${complement.limit > 1 ? ' itens' : ' item'} no total.`}
                                {complement.more_expensive_only && (
                                  <div style={{ marginTop: 10, color: 'red' }}>
                                    Apenas o valor do complemento mais caro será
                                    cobrado
                                  </div>
                                )}
                                {complement.optional !== true && (
                                  <p>Obrigatório</p>
                                )}
                              </QuestionSubtitle>
                            </>
                          ) : (
                            <>
                              <QuestionSubtitle>
                                {`Escolha até ${complement.limit} ${complement.limit > 1 ? ' itens' : ' item'
                                  } no total.`}
                                {complement.more_expensive_only && (
                                  <div style={{ marginTop: 20, color: 'red' }}>
                                    Apenas o valor do complemento mais caro será
                                    cobrado
                                  </div>
                                )}
                              </QuestionSubtitle>
                            </>
                          )}
                        </>
                      )}

                      {complement.limit < complement.minimum && (
                        <>
                          {complement.minimum > 1 ? (
                            <>
                              <QuestionSubtitle>
                                {`Escolha entre ${complement.minimum} e ${complement.limit
                                  }
                        ${complement.limit > 1 ? ' itens' : ' item'} no total.`}
                                {complement.more_expensive_only && (
                                  <div style={{ marginTop: 10, color: 'red' }}>
                                    Apenas o valor do complemento mais caro será
                                    cobrado
                                  </div>
                                )}
                                {complement.optional !== true && (
                                  <p>Obrigatório</p>
                                )}
                              </QuestionSubtitle>
                            </>
                          ) : (
                            <>
                              <QuestionSubtitle>
                                {`Escolha até ${complement.limit} ${complement.limit > 1 ? ' itens' : ' item'
                                  } no total.`}
                                {complement.more_expensive_only && (
                                  <div style={{ marginTop: 20, color: 'red' }}>
                                    Apenas o valor do complemento mais caro será
                                    cobrado
                                  </div>
                                )}
                              </QuestionSubtitle>
                            </>
                          )}
                        </>
                      )}

                      {complement.limit === complement.minimum && (
                        <>
                          {complement.minimum > 1 ? (
                            <>
                              <QuestionSubtitle>
                                {`Escolha ${complement.minimum} ${complement.limit > 1 ? ' itens' : ' item'
                                  } no total.`}
                                {complement.more_expensive_only && (
                                  <div style={{ marginTop: 10, color: 'red' }}>
                                    Apenas o valor do complemento mais caro será
                                    cobrado
                                  </div>
                                )}
                              </QuestionSubtitle>
                            </>
                          ) : (
                            <>
                              <QuestionSubtitle>
                                {`Escolha até ${complement.limit} ${complement.limit > 1 ? ' itens' : ' item'
                                  } no total.`}
                                {complement.more_expensive_only && (
                                  <div style={{ marginTop: 20, color: 'red' }}>
                                    Apenas o valor do complemento mais caro será
                                    cobrado
                                  </div>
                                )}
                              </QuestionSubtitle>
                            </>
                          )}
                        </>
                      )}
                    </div>
                    {complement.optional !== true && <p>Obrigatório</p>}
                  </ComplementsHeaderProduct>
                  <ComplementItem>
                    {complement.complements
                      .filter(comp => comp.available_in_delivery)
                      .filter(item => {
                        const filtroCorrespondente = complementsFiltered.find(
                          filtro =>
                            filtro.id === complement.id &&
                            filtro.nome.length >= 3,
                        );
                        const objetosFiltrados =
                          filtroCorrespondente &&
                          item.name
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .includes(filtroCorrespondente.nome.toLowerCase());
                        if (objetosFiltrados !== undefined)
                          return (
                            filtroCorrespondente &&
                            item.name
                              .toLowerCase()
                              .normalize('NFD')
                              .replace(/[\u0300-\u036f]/g, '')
                              .includes(filtroCorrespondente.nome.toLowerCase())
                          );
                        else return item;
                      })
                      .map(item => (
                        <li key={item.id}>
                          {item.image && (
                            <img
                              src={
                                item.image.url_thumb ||
                                item.image.url ||
                                fotoPadrao
                              }
                              alt={item.nome || 'foto'}
                              style={{ width: 75, height: 65, borderRadius: 8 }}
                            />
                          )}
                          <ComplementItemDescription>
                            <span>
                              <strong
                                style={{
                                  fontWeight:
                                    item.limit > 0 ? 'regular' : 'bold',
                                }}
                              >
                                {menuTranslationsEnabled &&
                                  menuLanguage === 'english'
                                  ? item?.translations?.en?.name
                                    ? item?.translations?.en?.name
                                    : item.name
                                  : item.name}
                              </strong>
                            </span>
                            {item.description && (
                              <span style={{ fontSize: 15 }}>
                                {menuTranslationsEnabled &&
                                  menuLanguage === 'english'
                                  ? item?.translations?.en?.description
                                    ? item?.translations?.en?.description
                                    : item.description
                                  : item.description}
                              </span>
                            )}
                            {(Number(item.delivery_price || item.price) > 0) &
                              (complement.additional === true) ? (
                              <span
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: 12,
                                  fontFamily: 'Arial',
                                }}
                              >
                                +{' '}
                                {formatValue(item.delivery_price || item.price)}
                              </span>
                            ) : null}
                          </ComplementItemDescription>

                          <ComplementItemAmount>
                            {complementsCategory
                              .filter(category => category.id === complement.id)
                              .map(cat =>
                                cat.complements
                                  .filter(
                                    complement => complement.id === item.id,
                                  )
                                  .map(filtered => {
                                    return (
                                      <React.Fragment key={complement.id}>
                                        <FaMinus
                                          size={18}
                                          color="#FF281E"
                                          onClick={() => {
                                            handleRemoveComplements(
                                              complement.id,
                                              item.id,
                                            );
                                          }}
                                        />

                                        <InputComplementAmount
                                          autoFocus={stateFocus.includes(
                                            filtered.id,
                                          )}
                                          value={filtered.amount}
                                          id={`${item.id}`}
                                          onChange={e =>
                                            handleChangeComplementAmount(
                                              maskOnlyNumbers(e.target.value),
                                              item.id,
                                              item.name,
                                              item.price,
                                              item.delivery_price,
                                              item.limit,
                                              complement.limit,
                                              complement.id,
                                              complement.additional,
                                              complement.more_expensive_only,
                                              complement.minimum,
                                              complement.use_average,
                                            )
                                          }
                                        />
                                      </React.Fragment>
                                    );
                                  }),
                              )}

                            {complementsCategory &&
                              complementsCategory.length > 0 &&
                              complementsCategory
                                .filter(
                                  category => category.id === complement.id,
                                )
                                .map(
                                  cat =>
                                    cat.complements.filter(
                                      complement => complement.id === item.id,
                                    ).length < 1 &&
                                    totalCategoryComplementsAmount(
                                      complement.id,
                                    ) < complement.limit &&
                                    item.limit > 0 && (
                                      <InputComplementAmount
                                        id={`${item.id}`}
                                        value={complement.amount}
                                        onChange={e =>
                                          handleChangeComplementAmount(
                                            maskOnlyNumbers(e.target.value),
                                            item.id,
                                            item.name,
                                            item.price,
                                            item.delivery_price,
                                            item.limit,
                                            complement.limit,
                                            complement.id,
                                            complement.additional,
                                            complement.more_expensive_only,
                                            complement.minimum,
                                            complement.use_average,
                                          )
                                        }
                                      />
                                    ),
                                )}

                            {complementsCategory &&
                              complementsCategory.length > 0
                              ? complementsCategory
                                .filter(
                                  category => category.id === complement.id,
                                )
                                .map(cat =>
                                  cat.complements.filter(
                                    complement => complement.id === item.id,
                                  ),
                                ).length < 1 &&
                              totalCategoryComplementsAmount(complement.id) <
                              complement.limit &&
                              item.limit > 0 && (
                                <InputComplementAmount
                                  id={`${item.id}`}
                                  value={complement.amount}
                                  onChange={e =>
                                    handleChangeComplementAmount(
                                      maskOnlyNumbers(e.target.value),
                                      item.id,
                                      item.name,
                                      item.price,
                                      item.delivery_price,
                                      item.limit,
                                      complement.limit,
                                      complement.id,
                                      complement.additional,
                                      complement.more_expensive_only,
                                      complement.minimum,
                                      complement.use_average,
                                    )
                                  }
                                />
                              )
                              : totalCategoryComplementsAmount(complement.id) <
                              complement.limit &&
                              item.limit > 0 && (
                                <InputComplementAmount
                                  id={`${item.id}`}
                                  value={complement.amount}
                                  onChange={e =>
                                    handleChangeComplementAmount(
                                      maskOnlyNumbers(e.target.value),
                                      item.id,
                                      item.name,
                                      item.price,
                                      item.delivery_price,
                                      item.limit,
                                      complement.limit,
                                      complement.id,
                                      complement.additional,
                                      complement.more_expensive_only,
                                      complement.minimum,
                                      complement.use_average,
                                    )
                                  }
                                />
                              )}

                            {totalCategoryComplementsAmount(complement.id) <
                              complement.limit &&
                              item.limit > 0 && (
                                <TiPlus
                                  size={18}
                                  color="#FF281E"
                                  onClick={() => {
                                    handleAddComplements(
                                      item.id,
                                      item.name,
                                      item.price,
                                      item.delivery_price,
                                      item.limit,
                                      complement.limit,
                                      complement.id,
                                      complement.additional,
                                      complement.more_expensive_only,
                                      complement.minimum,
                                      complement.use_average,
                                      complement.cheapest,
                                      complement.cheapest_delivery,
                                    );
                                  }}
                                />
                              )}
                          </ComplementItemAmount>
                        </li>
                      ))}
                  </ComplementItem>
                </React.Fragment>
              ))}

            <InformationText>
              <p>
                <FiMessageCircle size={18} color="#666666" />
                Quer fazer alguma observação?
              </p>
              <input
                placeholder='Ex: Tirar item "X"'
                value={observation}
                onChange={e => {
                  handleAddObservation(e);
                }}
                maxLength="150"
              />
            </InformationText>
          </div>
        </AddItemsProduct>
        {(restaurantWithdrawalActive.toString() === 'true' ||
          restaurantDeliveryActive.toString() === 'true' ||
          orderSchedulingActive.toString() === 'true') && (
            <Footer>
              {product.sold_off ? (
                <button type="button">
                  <span>
                    <FiAlertCircle size={22} />
                  </span>
                  Produto Esgotado
                </button>
              ) : (
                <>
                  <BackButton onClick={handleCloseProductModal}>
                    <RiArrowDropLeftLine size={20} style={{ padding: 0 }} />
                    <span>Voltar</span>
                  </BackButton>
                  <OrderButton onClick={addToCarts} type="button">
                    Adicionar{' '}
                    {isProductWeight ? (
                      <span>
                        {isCourtesy
                          ? ''
                          : formatValue(
                            amount *
                            (Number(totalComplementsPrice) +
                              Number(product.price * inputProductWeight) +
                              (product.is_combo
                                ? startingPrice(
                                  product.complement_categories,
                                  isDelivery,
                                )
                                : 0)),
                          )}
                      </span>
                    ) : (
                      <span>
                        {isCourtesy
                          ? ''
                          : formatValue(
                            amount *
                            (Number(totalComplementsPrice) +
                              Number(product.price) +
                              (product.is_combo
                                ? startingPrice(
                                  product.complement_categories,
                                  isDelivery,
                                )
                                : 0)),
                          )}
                      </span>
                    )}
                  </OrderButton>
                </>
              )}
            </Footer>
          )}
      </Content>
    </Container>
  ) : (
    <Container>
      <Content>
        <Modal
          isOpen={modalIsOpen}
          toggle={toggleModal}
          style={{ padding: 10 }}
          onClosed={closeModal}
        >
          <ModalTitle>
            <p>{modalPhrase}</p>
          </ModalTitle>

          <ModalFooter>
            <ConfirmButton onClick={closeModal}>Ok, entendi</ConfirmButton>
          </ModalFooter>
        </Modal>

        <CloseButton type="button" onClick={handleCloseProductModal}>
          <FiX size={20} color="red" />
        </CloseButton>

        {product.image && (
          <ImageProduct>
            <img
              src={product.image.url_thumb || product.image.url}
              alt={product.description}
            />
          </ImageProduct>
        )}

        <DescriptionProduct>
          {!product.image ? (
            <ProductName>{product.name}</ProductName>
          ) : (
            <h1>{product.name}</h1>
          )}

          <span>{product.description}</span>
        </DescriptionProduct>

        <AddItemsProduct>
          <div>
            <EditAction>
              {isProductWeight.toString() === 'true' ? (
                <span>Peso do Produto (kg)</span>
              ) : (
                <span>Quantidade</span>
              )}
              <EditActionButtons>
                {isProductWeight ? (
                  <InputProductWeight
                    type="number"
                    placeholder="ex: 0,800"
                    onChange={e => {
                      handleChangeProductWeight(e);
                    }}
                  />
                ) : (
                  <>
                    <button onClick={decrement} type="button">
                      <FaMinus
                        size={18}
                        color={amount <= 1 ? '#CDCDCD' : '#FF281E'}
                      />
                    </button>
                    {!product?.is_unitary ? (
                      <InputProductAmount
                        value={amount}
                        onChange={e =>
                          handleChangeProductAmount(
                            maskOnlyNumbers(e.target.value),
                          )
                        }
                      />
                    ) : (
                      <span style={{ color: '#FF281E', fontSize: 20 }}>1</span>
                    )}

                    {!product?.is_unitary && (
                      <button onClick={increment} type="button">
                        <TiPlus size={18} color="#FF281E" />
                      </button>
                    )}
                  </>
                )}
              </EditActionButtons>
            </EditAction>

            {product.complement_categories
              .filter(item => item.available)
              .filter(
                item =>
                  item.complements.filter(compl => compl.available).length > 0,
              )
              .map(complement => (
                <React.Fragment key={complement.id}>
                  <ComplementsHeaderProduct>
                    <div>
                      <div>
                        {menuTranslationsEnabled && menuLanguage === 'english'
                          ? complement?.translations?.en?.question
                            ? complement?.translations?.en?.question
                            : complement.question
                          : complement.question}
                      </div>
                      {complement.limit > complement.minimum && (
                        <>
                          {complement.minimum > 1 ? (
                            <QuestionSubtitle>
                              {`Escolha entre ${complement.minimum} e ${complement.limit
                                }
                        ${complement.limit > 1 ? ' itens' : ' item'} no total.`}
                              {complement.more_expensive_only && (
                                <div style={{ marginTop: 10, color: 'red' }}>
                                  Apenas o valor do complemento mais caro será
                                  cobrado
                                </div>
                              )}

                              {complement.optional !== true && (
                                <p>Obrigatório</p>
                              )}
                            </QuestionSubtitle>
                          ) : (
                            <QuestionSubtitle>
                              {`Escolha até ${complement.limit} ${complement.limit > 1 ? ' itens' : ' item'
                                } no total.`}
                              {complement.more_expensive_only && (
                                <div style={{ marginTop: 20, color: 'red' }}>
                                  Apenas o valor do complemento mais caro será
                                  cobrado
                                </div>
                              )}
                              {complement.optional !== true && (
                                <p>Obrigatório</p>
                              )}
                            </QuestionSubtitle>
                          )}
                        </>
                      )}
                      {complement.limit < complement.minimum && (
                        <>
                          {complement.minimum > 1 ? (
                            <QuestionSubtitle>
                              {`Escolha ${complement.minimum
                                }                        ${complement.limit > 1 ? ' itens' : ' item'
                                } no mínimo.`}
                              {complement.more_expensive_only && (
                                <div style={{ marginTop: 10, color: 'red' }}>
                                  Apenas o valor do complemento mais caro será
                                  cobrado
                                </div>
                              )}
                              {complement.optional !== true && (
                                <p>Obrigatório</p>
                              )}
                            </QuestionSubtitle>
                          ) : (
                            <QuestionSubtitle>
                              {`Escolha até ${complement.limit} ${complement.limit > 1 ? ' itens' : ' item'
                                } no total.`}
                              {complement.more_expensive_only && (
                                <div style={{ marginTop: 20, color: 'red' }}>
                                  Apenas o valor do complemento mais caro será
                                  cobrado
                                </div>
                              )}
                              {complement.optional !== true && (
                                <p>Obrigatório</p>
                              )}
                            </QuestionSubtitle>
                          )}
                        </>
                      )}

                      {complement.limit === complement.minimum && (
                        <>
                          {complement.minimum > 1 ? (
                            <QuestionSubtitle>
                              {`Escolha ${complement.minimum} ${complement.limit > 1 ? ' itens' : ' item'
                                } no total.`}
                              {complement.more_expensive_only && (
                                <div style={{ marginTop: 10, color: 'red' }}>
                                  Apenas o valor do complemento mais caro será
                                  cobrado
                                </div>
                              )}
                              {complement.optional !== true && (
                                <p>Obrigatório</p>
                              )}
                            </QuestionSubtitle>
                          ) : (
                            <QuestionSubtitle>
                              {`Escolha até ${complement.limit} ${complement.limit > 1 ? ' itens' : ' item'
                                } no total.`}
                              {complement.more_expensive_only && (
                                <div style={{ marginTop: 20, color: 'red' }}>
                                  Apenas o valor do complemento mais caro será
                                  cobrado
                                </div>
                              )}
                              {complement.optional !== true && (
                                <p>Obrigatório</p>
                              )}
                            </QuestionSubtitle>
                          )}
                        </>
                      )}
                    </div>

                    {complement.complements.length > 10 && (
                      <SearchBar>
                        <ButtonSearchInput>
                          <FiSearch size={17} color="#6C6C6C" />
                        </ButtonSearchInput>
                        <input
                          name="product"
                          type="text"
                          placeholder="Buscar Complemento"
                          onChange={e =>
                            addOrUpdateFilter(complement.id, e.target.value)
                          }
                          id={complement?.name}
                          ref={inputComplementRef}
                          style={{ display: 'flex' }}
                        />

                        <ButtonDeleteInput
                          type="button"
                          title="Mostrar todos"
                          onClick={() => {
                            deleteValue(complement);
                          }}
                        >
                          <FiX size={17} color="#CDCDCD" />
                        </ButtonDeleteInput>
                      </SearchBar>
                    )}
                  </ComplementsHeaderProduct>
                  <ComplementItem>
                    {complement.complements
                      .filter(comp => comp.available)
                      .filter(item => {
                        const filtroCorrespondente = complementsFiltered.find(
                          filtro =>
                            filtro.id === complement.id &&
                            filtro.nome.length >= 3,
                        );
                        const objetosFiltrados =
                          filtroCorrespondente &&
                          item.name
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .includes(filtroCorrespondente.nome.toLowerCase());
                        if (objetosFiltrados !== undefined)
                          return (
                            filtroCorrespondente &&
                            item.name
                              .toLowerCase()
                              .normalize('NFD')
                              .replace(/[\u0300-\u036f]/g, '')
                              .includes(filtroCorrespondente.nome.toLowerCase())
                          );
                        else return item;
                      })
                      .map(item => (
                        <li key={item.id}>
                          {item.image && (
                            <img
                              src={
                                item.image.url_thumb ||
                                item.image.url ||
                                fotoPadrao
                              }
                              alt={item.nome || 'foto'}
                              style={{ width: 75, height: 65, borderRadius: 8 }}
                            />
                          )}
                          <ComplementItemDescription>
                            <span>
                              <strong
                                style={{
                                  fontWeight:
                                    item.limit > 0 ? 'regular' : 'bold',
                                }}
                              >
                                {menuTranslationsEnabled &&
                                  menuLanguage === 'english'
                                  ? item?.translations?.en?.name
                                    ? item?.translations?.en?.name
                                    : item.name
                                  : item.name}
                              </strong>
                            </span>
                            {item.description && (
                              <span style={{ fontSize: 15 }}>
                                {menuTranslationsEnabled &&
                                  menuLanguage === 'english'
                                  ? item?.translations?.en?.description
                                    ? item?.translations?.en?.description
                                    : item.description
                                  : item.description}
                              </span>
                            )}
                            {(Number(item.price) > 0) &
                              (complement.additional === true) ? (
                              <span
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: 12,
                                  fontFamily: 'Arial',
                                }}
                              >
                                + {formatValue(item.price)}
                              </span>
                            ) : null}
                          </ComplementItemDescription>

                          <ComplementItemAmount>
                            {complementsCategory
                              .filter(category => category.id === complement.id)
                              .map(cat =>
                                cat.complements
                                  .filter(
                                    complement => complement.id === item.id,
                                  )
                                  .map(filtered => {
                                    return (
                                      <React.Fragment key={complement.id}>
                                        <FaMinus
                                          size={18}
                                          color="#FF281E"
                                          onClick={() => {
                                            handleRemoveComplements(
                                              complement.id,
                                              item.id,
                                            );
                                          }}
                                        />

                                        <InputComplementAmount
                                          autoFocus={stateFocus.includes(
                                            filtered.id,
                                          )}
                                          value={filtered.amount}
                                          id={`${item.id}`}
                                          onChange={e =>
                                            handleChangeComplementAmount(
                                              maskOnlyNumbers(e.target.value),
                                              item.id,
                                              item.name,
                                              item.price,
                                              item.delivery_price,
                                              item.limit,
                                              complement.limit,
                                              complement.id,
                                              complement.additional,
                                              complement.more_expensive_only,
                                              complement.minimum,
                                              complement.use_average,
                                            )
                                          }
                                        />
                                      </React.Fragment>
                                    );
                                  }),
                              )}

                            {complementsCategory &&
                              complementsCategory.length > 0 &&
                              complementsCategory
                                .filter(
                                  category => category.id === complement.id,
                                )
                                .map(
                                  cat =>
                                    cat.complements.filter(
                                      complement => complement.id === item.id,
                                    ).length < 1 &&
                                    totalCategoryComplementsAmount(
                                      complement.id,
                                    ) < complement.limit &&
                                    item.limit > 0 && (
                                      <InputComplementAmount
                                        key={item.id}
                                        id={`${item.id}`}
                                        value={complement.amount}
                                        onChange={e =>
                                          handleChangeComplementAmount(
                                            maskOnlyNumbers(e.target.value),
                                            item.id,
                                            item.name,
                                            item.price,
                                            item.delivery_price,
                                            item.limit,
                                            complement.limit,
                                            complement.id,
                                            complement.additional,
                                            complement.more_expensive_only,
                                            complement.minimum,
                                            complement.use_average,
                                          )
                                        }
                                      />
                                    ),
                                )}

                            {complementsCategory &&
                              complementsCategory.length > 0
                              ? complementsCategory
                                .filter(
                                  category => category.id === complement.id,
                                )
                                .map(cat =>
                                  cat.complements.filter(
                                    complement => complement.id === item.id,
                                  ),
                                ).length < 1 &&
                              totalCategoryComplementsAmount(complement.id) <
                              complement.limit &&
                              item.limit > 0 && (
                                <InputComplementAmount
                                  id={`${item.id}`}
                                  value={complement.amount}
                                  onChange={e =>
                                    handleChangeComplementAmount(
                                      maskOnlyNumbers(e.target.value),
                                      item.id,
                                      item.name,
                                      item.price,
                                      item.delivery_price,
                                      item.limit,
                                      complement.limit,
                                      complement.id,
                                      complement.additional,
                                      complement.more_expensive_only,
                                      complement.minimum,
                                      complement.use_average,
                                    )
                                  }
                                />
                              )
                              : totalCategoryComplementsAmount(complement.id) <
                              complement.limit &&
                              item.limit > 0 && (
                                <InputComplementAmount
                                  id={`${item.id}`}
                                  value={complement.amount}
                                  onChange={e =>
                                    handleChangeComplementAmount(
                                      maskOnlyNumbers(e.target.value),
                                      item.id,
                                      item.name,
                                      item.price,
                                      item.delivery_price,
                                      item.limit,
                                      complement.limit,
                                      complement.id,
                                      complement.additional,
                                      complement.more_expensive_only,
                                      complement.minimum,
                                      complement.use_average,
                                    )
                                  }
                                />
                              )}

                            {totalCategoryComplementsAmount(complement.id) <
                              complement.limit &&
                              item.limit > 0 && (
                                <TiPlus
                                  size={18}
                                  color="#FF281E"
                                  onClick={() => {
                                    handleAddComplements(
                                      item.id,
                                      item.name,
                                      item.price,
                                      item.delivery_price,
                                      item.limit,
                                      complement.limit,
                                      complement.id,
                                      complement.additional,
                                      complement.more_expensive_only,
                                      complement.minimum,
                                      complement.use_average,
                                      complement.cheapest,
                                      complement.cheapest_delivery,
                                    );
                                  }}
                                />
                              )}
                          </ComplementItemAmount>
                        </li>
                      ))}
                  </ComplementItem>
                </React.Fragment>
              ))}

            <InformationText>
              <p>
                <FiMessageCircle size={18} color="#666666" />
                Quer fazer alguma observação?
              </p>
              <input
                placeholder='Ex: Tirar item "X"'
                value={observation}
                onChange={e => {
                  handleAddObservation(e);
                }}
                maxLength="150"
              />
            </InformationText>
          </div>
        </AddItemsProduct>
        {restaurantIsOpen.toString() === 'true' && tableType !== 'menu' && (
          <Footer>
            {product.sold_off ? (
              <button type="button">
                <span>
                  <FiAlertCircle size={22} />
                </span>
                Produto Esgotado
              </button>
            ) : (
              <>
                <BackButton onClick={handleCloseProductModal}>
                  <RiArrowDropLeftLine
                    size={30}
                    style={{ marginTop: 0, marginLeft: -3 }}
                  />
                  <span>Voltar</span>
                </BackButton>
                <OrderButton onClick={addToCarts} type="button">
                  Adicionar&nbsp;
                  {isProductWeight ? (
                    <span>
                      {isCourtesy
                        ? ''
                        : formatValue(
                          amount *
                          (Number(totalComplementsPrice) +
                            Number(
                              product.price * inputProductWeight +
                              (product.is_combo
                                ? startingPrice(
                                  product.complement_categories,
                                  isDelivery,
                                )
                                : 0),
                            )),
                        )}
                    </span>
                  ) : (
                    <span>
                      {isCourtesy
                        ? ''
                        : formatValue(
                          amount *
                          (Number(totalComplementsPrice) +
                            Number(product.price) +
                            (product.is_combo
                              ? startingPrice(
                                product.complement_categories,
                                isDelivery,
                              )
                              : 0)),
                        )}
                    </span>
                  )}
                </OrderButton>
              </>
            )}
          </Footer>
        )}
      </Content>
    </Container>
  );
};

export default Product;
