import React from 'react';
import { Switch, Route } from 'react-router-dom';

import CodeLogin from '../pages/CodeLogin';
import Login from '../pages/Login';
import BitLogin from '../pages/BitLogin';
import Menu from '../pages/Menu';
import Cart from '../pages/Cart';
import CartDelivery from '../pages/Delivery/CartDelivery';
import CloseAccount from '../pages/CloseAccount';
import Product from '../pages/Product';
import ConfirmOrder from '../pages/ConfirmOrder';
import RestaurantRating from '../pages/RestaurantRating';
import Welcome from '../pages/Welcome';
import WelcomeFromCode from '../pages/WelcomeFromCode';
import Home from '../pages/Home';
import Goodbye from '../pages/Goodbye';
import LocationError from '../pages/LocationError';
import FarLocationError from '../pages/FarLocationError';
import NeedLocation from '../pages/NeedLocation';

import { CartProvider } from '../context/cart';
import { OrderProvider } from '../context/order';
import { AuthProvider } from '../context/auth';
import ChoosePayments from '../pages/Delivery/ChoosePayments';
import ConfirmOrderDelivery from '../pages/Delivery/ConfirmOrder';
import DeliveryType from '../pages/Delivery/DeliveryType';
import SearchAddressByDistance from '../pages/Delivery/SearchAddressByDistance';
import SearchAddress from '../pages/Delivery/SearchAddress';
import AlmostThere from '../pages/Delivery/AlmostThere';
import Orders from '../pages/Delivery/Orders';
import OrdersDetails from '../pages/Delivery/Orders/OrdersDetails';
import RedirectDelivery from '../pages/RedirectDelivery';
import { BasketsProvider } from '../context/BasketsContext';
import SystemError from '../pages/SystemError';
import SystemDeliveryError from '../pages/SystemDeliveryError';
import { SchedulerPage } from '../pages/Delivery/SchedulerPage';
import InputCardCloseTable from '../pages/InputCardCloseTable';
import InputCard from '../pages/InputCard';

import PaymentMethods from '../pages/PrePaid/PaymentMethods';
import PrepaidAlmostThere from '../pages/PrePaid/AlmostThere';
import RestaurantRatingWithoutOrder from '../pages/RestaurantRatingWithoutOrder';
import { ReviewsPage } from '../pages/ReviewsPage';
import { ClubRegister } from '../pages/ClubRegister';
import { ClubFormProvider } from '../context/clubForm';

const Routes = () => {
  return (
    <Switch>
      <AuthProvider>
        <OrderProvider>
          <CartProvider>
            <BasketsProvider>
              <Route path="/" exact component={Home} />
              <Route path="/app/auto" exact component={CodeLogin} />
              <Route path="/:username" component={RedirectDelivery} exact />
              <Route path="/app/bit" exact component={BitLogin} />
              <Route path="/auto/:restaurant_name" exact component={Login} />

              <Route
                path="/auto/:restaurant_name/:restaurant_code"
                component={WelcomeFromCode}
                exact
              />

              <Route
                path="/app/needLocation"
                isPrivate
                component={NeedLocation}
              />
              <Route
                path="/app/farlocationerror"
                isPrivate
                component={FarLocationError}
              />

              <Route
                path="/app/systemError"
                isPrivate
                component={SystemError}
              />

              <Route
                path="/app/systemDeliveryError"
                isPrivate
                component={SystemDeliveryError}
              />

              <Route path="/menu" isPrivate component={Menu} />
              <Route path="/app/welcome" isPrivate component={Welcome} />
              <Route path="/app/cart" isPrivate component={Cart} />
              <Route
                path="/app/cart-delivery"
                isPrivate
                component={CartDelivery}
              />
              <Route
                path="/app/payments-delivery"
                isPrivate
                component={ChoosePayments}
              />
              <Route path="/app/schedule" isPrivate component={SchedulerPage} />
              <Route
                path="/app/delivery-type"
                isPrivate
                component={DeliveryType}
              />
              <Route
                path="/app/confirm-order-delivery"
                isPrivate
                component={ConfirmOrderDelivery}
              />
              <Route
                path="/app/search-address-by-distance-delivery"
                isPrivate
                component={SearchAddressByDistance}
              />
              <Route
                path="/app/search-address-delivery"
                isPrivate
                component={SearchAddress}
              />
              <Route path="/app/delivery-orders" isPrivate component={Orders} />
              <Route
                path="/app/delivery-orders-details"
                isPrivate
                component={OrdersDetails}
              />
              <Route
                path="/app/almost-there"
                isPrivate
                component={AlmostThere}
              />
              <Route
                path="/app/prepaid/payment-methods"
                isPrivate
                component={PaymentMethods}
              />
              <Route
                path="/app/prepaid/almost-there"
                isPrivate
                component={PrepaidAlmostThere}
              />
              <Route
                path="/app/locationError"
                exact
                isPrivate
                component={LocationError}
              />
              <Route
                path="/app/closeaccount"
                isPrivate
                component={CloseAccount}
              />
              <Route path="/app/product" isPrivate component={Product} />
              <Route
                path="/app/confirmorder"
                isPrivate
                component={ConfirmOrder}
              />
              <Route
                path="/app/rating"
                isPrivate
                component={RestaurantRating}
              />
              <Route path="/app/review" isPrivate component={ReviewsPage} />

              <Route
                path="/app/restaurant-rating"
                isPrivate
                component={RestaurantRatingWithoutOrder}
              />
              <Route path="/app/goodbye" isPrivate component={Goodbye} />

              <Route path="/app/inputcard" isPrivate component={InputCard} />
              <Route
                path="/app/close-table-credit"
                isPrivate
                component={InputCardCloseTable}
              />

              <ClubFormProvider>
                <Route
                  path="/club/register"
                  isPrivate
                  component={ClubRegister}
                />
              </ClubFormProvider>
            </BasketsProvider>
          </CartProvider>
        </OrderProvider>
      </AuthProvider>
    </Switch>
  );
};

export default Routes;
