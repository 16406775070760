import io from 'socket.io-client';

export default class WebsocketManager {
  constructor(token) {
    this.token = token;

    this.messageCallbacks = new Map();

    const wss_url = (process.env.REACT_APP_API_URL || '').replace('http', 'ws');

    this.wss_url = `${wss_url}/buyers`;

    this.socket = null;

    this.socketConnect();
  }

  socketConnect() {
    if (this.token) {
      this.socket = io(this.wss_url, {
        reconnectionDelayMax: 10000,
        transports: ['websocket'],
        query: {
          token: this.token,
        },
      });

      this.socket.on('event', message => {
        this.onMessage(JSON.parse(message));
      });
    } else {
      console.warn('Could not init websocket manager.');
    }
  }

  onMessage(data) {
    for (const cb of this.messageCallbacks.values()) {
      cb(data);
    }
  }

  addMessageCallback(id, callback) {
    this.messageCallbacks.set(id, callback);
  }

  checkConnection() {
    // check if websocket instance is closed, if so call `connect` function.
    if (!this.socket && this.token !== '') {
      this.socketConnect();
    }
  }

  close() {
    if (this.socket) {
      this.socket.close();
      this.token = '';
      this.socket = null;
    }
  }
}
